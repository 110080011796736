if (!window.Countly || !Array.isArray(window.Countly.q)) {
  window.Countly = { q: [] }
}

// Define the event tracking function
function trackEvent(eventName, eventData) {
  if (window.Countly.q) {
    window.Countly.q.push([
      'add_event',
      { key: eventName, segmentation: eventData },
    ])
  }
}

// Exported functions
export function focusLocation(locationId, partnerId) {
  trackEvent('locationFocused', { locationId, partnerId })
}

export function search(address, partnerId) {
  trackEvent('searchStarted', { address, partnerId })
}

export function inteliblyRequestOpened(locationId, partnerId) {
  trackEvent('inteliblyRequestOpened', { locationId, partnerId })
}

export function scheduleConnectOpened(locationId, partnerId) {
  trackEvent('scheduleConnectOpened', { locationId, partnerId })
}

export function scheduleWidgetOpened(locationId, partnerId) {
  trackEvent('scheduleWidgetOpened', { locationId, partnerId })
}

export function dolphinBookingOpened(locationId, partnerId) {
  trackEvent('dolphinBookingOpened', { locationId, partnerId })
}

export const pushAnalytics = (path, partnerId) => {
  let page_path = path
  if (partnerId) {
    page_path = `/partners/${partnerId}${page_path}`
  }

  window.Countly.q.push(['track_pageview', page_path])
}
