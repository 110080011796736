import { AppointmentTypeModelBase } from "./AppointmentTypeModel.base"


/* A graphql query fragment builders for AppointmentTypeModel */
export { selectFromAppointmentType, appointmentTypeModelPrimitives, AppointmentTypeModelSelector } from "./AppointmentTypeModel.base"

/**
 * AppointmentTypeModel
 *
 * An appointment type
 */
export const AppointmentTypeModel = AppointmentTypeModelBase
