/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AppointmentTypeModel } from "./AppointmentTypeModel"
import { AppointmentTypeModelSelector } from "./AppointmentTypeModel.base"
import { DolphinReservationModel } from "./DolphinReservationModel"
import { DolphinReservationModelSelector } from "./DolphinReservationModel.base"
import { FindAProviderModel } from "./FindAProviderModel"
import { FindAProviderModelSelector } from "./FindAProviderModel.base"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { NotificationModel } from "./NotificationModel"
import { NotificationModelSelector } from "./NotificationModel.base"
import { NotificationStatus } from "./NotificationStatusEnum"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"
import { PatientModel } from "./PatientModel"
import { PatientModelSelector } from "./PatientModel.base"
import { PatientSource } from "./PatientSourceEnum"
import { PmsAvailableAppointmentModel } from "./PmsAvailableAppointmentModel"
import { PmsAvailableAppointmentModelSelector } from "./PmsAvailableAppointmentModel.base"
import { PracticeAppointmentAnalyticsModel } from "./PracticeAppointmentAnalyticsModel"
import { PracticeAppointmentAnalyticsModelSelector } from "./PracticeAppointmentAnalyticsModel.base"
import { PracticeModel } from "./PracticeModel"
import { PracticeModelSelector } from "./PracticeModel.base"
import { ProviderAppointmentAnalyticsModel } from "./ProviderAppointmentAnalyticsModel"
import { ProviderAppointmentAnalyticsModelSelector } from "./ProviderAppointmentAnalyticsModel.base"
import { ProviderModel } from "./ProviderModel"
import { ProviderModelSelector } from "./ProviderModel.base"
import { BoundingBox } from "./RootStore.base"
import { SearchLocationsModel } from "./SearchLocationsModel"
import { SearchLocationsModelSelector } from "./SearchLocationsModel.base"
import { SpecialtyModel } from "./SpecialtyModel"
import { SpecialtyModelSelector } from "./SpecialtyModel.base"


/**
 * RootQueryTypeBase
 * auto generated base class for the model RootQueryTypeModel.
 */
export const RootQueryTypeModelBase = ModelBase
  .named('RootQueryType')
  .props({
    __typename: types.optional(types.literal("RootQueryType"), "RootQueryType"),
    /** List appointment types for a practice */
    appointmentTypes: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => AppointmentTypeModel))))),
    /** List Dolphin reservations */
    dolphinReservations: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => DolphinReservationModel)))),
    /** Find a provider */
    findAProvider: types.union(types.undefined, types.null, types.late(() => FindAProviderModel)),
    /** Get a location */
    location: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => LocationModel))),
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    /** List notifications for authenticated user's practice. Defaults to unread. */
    notifications: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => NotificationModel))))),
    /** Get a partner */
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
    /** List all partners */
    partners: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PartnerModel))))),
    /** Get a patient */
    patient: types.union(types.undefined, types.null, types.late(() => PatientModel)),
    /** List available appointments for PMS integration */
    pmsAvailableAppointments: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => PmsAvailableAppointmentModel)))),
    /** Get a practice */
    practice: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PracticeModel))),
    /** List appointment analytics for all practices */
    practiceAppointmentAnalytics: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PracticeAppointmentAnalyticsModel))))),
    /** Get all Practices */
    practices: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PracticeModel))))),
    /** Get provider type */
    provider: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => ProviderModel))),
    /** List appointment analytics for all providers */
    providerAppointmentAnalytics: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => ProviderAppointmentAnalyticsModel))))),
    /** Search locations with bounding box of coordinates */
    searchLocations: types.union(types.undefined, types.null, types.late(() => SearchLocationsModel)),
    /** List specialties */
    specialties: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => SpecialtyModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class RootQueryTypeModelSelector extends QueryBuilder {
  appointmentTypes(builder) { return this.__child(`appointmentTypes`, AppointmentTypeModelSelector, builder) }
  dolphinReservations(builder, args) { return this.__child(`dolphinReservations` + (args ? '(' + ['endDateUtc', 'locationId', 'startDateUtc'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), DolphinReservationModelSelector, builder) }
  findAProvider(builder, args) { return this.__child(`findAProvider` + (args ? '(' + ['address', 'apptDate', 'apptTime', 'partnerId', 'radiusInMiles', 'specialty'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), FindAProviderModelSelector, builder) }
  location(builder, args) { return this.__child(`location` + (args ? '(' + ['id'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), LocationModelSelector, builder) }
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  notifications(builder, args) { return this.__child(`notifications` + (args ? '(' + ['status'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), NotificationModelSelector, builder) }
  partner(builder, args) { return this.__child(`partner` + (args ? '(' + ['id'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), PartnerModelSelector, builder) }
  partners(builder) { return this.__child(`partners`, PartnerModelSelector, builder) }
  patient(builder, args) { return this.__child(`patient` + (args ? '(' + ['id', 'source'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), PatientModelSelector, builder) }
  pmsAvailableAppointments(builder, args) { return this.__child(`pmsAvailableAppointments` + (args ? '(' + ['endDateUtc', 'locationId', 'startDateUtc'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), PmsAvailableAppointmentModelSelector, builder) }
  practice(builder, args) { return this.__child(`practice` + (args ? '(' + ['id'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), PracticeModelSelector, builder) }
  practiceAppointmentAnalytics(builder, args) { return this.__child(`practiceAppointmentAnalytics` + (args ? '(' + ['fromDate', 'toDate'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), PracticeAppointmentAnalyticsModelSelector, builder) }
  practices(builder) { return this.__child(`practices`, PracticeModelSelector, builder) }
  provider(builder, args) { return this.__child(`provider` + (args ? '(' + ['id'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), ProviderModelSelector, builder) }
  providerAppointmentAnalytics(builder, args) { return this.__child(`providerAppointmentAnalytics` + (args ? '(' + ['fromDate', 'toDate'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), ProviderAppointmentAnalyticsModelSelector, builder) }
  searchLocations(builder, args) { return this.__child(`searchLocations` + (args ? '(' + ['apptDate', 'apptTime', 'boundingBox', 'partnerId', 'specialty'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), SearchLocationsModelSelector, builder) }
  specialties(builder) { return this.__child(`specialties`, SpecialtyModelSelector, builder) }
}
export function selectFromRootQueryType() {
  return new RootQueryTypeModelSelector()
}

export const rootQueryTypeModelPrimitives = selectFromRootQueryType()
