/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from "mobx-state-tree"



/**
* NotificationStatus
*/
export const NotificationStatusEnumType = types.enumeration("NotificationStatus", [
        "ALL",
  "READ",
  "UNREAD",
      ])
