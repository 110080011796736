import { SearchCoordsModelBase } from "./SearchCoordsModel.base"


/* A graphql query fragment builders for SearchCoordsModel */
export { selectFromSearchCoords, searchCoordsModelPrimitives, SearchCoordsModelSelector } from "./SearchCoordsModel.base"

/**
 * SearchCoordsModel
 */
export const SearchCoordsModel = SearchCoordsModelBase
