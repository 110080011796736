import { types, getRoot, getEnv } from 'mobx-state-tree'

import { boxFromCoordinates } from '../utils/helpers'
import { PRACTICE } from './queries'

export const PracticeStore = types
  .model('PracticeStore')
  .views(self => ({
    get store() {
      return getRoot(self)
    },
  }))
  .actions(self => ({
    maybeFetchPractice(practiceId) {
      if (self.store.practices.has(practiceId)) {
        self.store.debug(`NOT LOADING PRACTICE WITH ID ${practiceId}`)
      } else {
        self.store.debug(`LOADING PRACTICE WITH ID ${practiceId}`)
        self.store.query(PRACTICE, { id: practiceId }).then(
          result => {
            if (!result && !result.practice) {
              return undefined
            }
            if (result.practice.locations.length > 1) {
              // Create a bounding box from the practice locations
              const locationCoordinates = result.practice.locations.map(
                location => ({ lat: location.lat, lng: location.lng })
              )
              const boundingBox = boxFromCoordinates(locationCoordinates)

              // Set the map center to the bounding box of the practice locations
              self.store.setMapCenter(boundingBox)
            } else {
              self.store.setLocationId(result.practice.locations[0].id)
            }
          },
          error => {
            getEnv(self).logger.error(error)
          }
        )
      }
    },
  }))
