import { useQuery } from '../models/reactUtils'
import {
  AdvancedMarker,
  APILoadingStatus,
  InfoWindow,
  Map,
  Pin,
  useAdvancedMarkerRef,
  useApiIsLoaded,
  useApiLoadingStatus,
  useMap,
} from '@vis.gl/react-google-maps'
import { observer } from 'mobx-react'
import React, { Suspense, useEffect } from 'react'

import { useTheme } from '@mui/material/styles'

import { GOOGLE_MAPS_ID } from '../constants'
import { logger } from '../utils/logger'
import InfoWindowContent from './InfoWindowContent'
import LanguageSelect from './LanguageSelect'
import Loading from './Loading'
import MapLoader from './MapLoader'

const MapContainer = observer(({ toggleDrawer }) => {
  const { store } = useQuery()
  const theme = useTheme()
  const locations = store.locationStore.locations
  const focusedLocation = store.locationStore.focusLocation
  const [markerRef, marker] = useAdvancedMarkerRef()
  const isApiLoaded = useApiIsLoaded()
  const status = useApiLoadingStatus()
  const map = useMap()
  const loading = store.loading

  useEffect(() => {
    if (status === APILoadingStatus.FAILED) {
      logger.error(status)
    }
  }, [status])

  useEffect(() => {
    if (map && store.boundingBox) {
      map.fitBounds(store.boundingBox)
    }
  }, [map, store.boundingBox])

  if (!isApiLoaded) {
    return <MapLoader message={'Loading Map...'} />
  }

  return (
    <Map
      mapId={GOOGLE_MAPS_ID}
      defaultCenter={{ lat: 39.833333, lng: -98.585522 }}
      disableDefaultUI={true}
      zoomControl={true}
      defaultZoom={5}
      style={{ width: '100%', height: '100vh' }}
    >
      <Suspense fallback={<MapLoader message={'Loading Translations...'} />}>
        <LanguageSelect />
      </Suspense>
      {loading ? (
        <MapLoader message={'Loading Locations...'} />
      ) : (
        locations &&
        locations.map(location => (
          <AdvancedMarker
            key={location.id}
            ref={markerInstance =>
              focusedLocation && focusedLocation.id === location.id
                ? markerRef(markerInstance)
                : undefined
            }
            position={{ lat: location.latNumber, lng: location.lngNumber }}
            onClick={() => {
              store.locationStore.setFocusLocation(location)
              toggleDrawer(true)
            }}
          >
            <Pin
              borderColor={theme.palette.primary.main}
              background={theme.palette.primary.light}
              glyphColor={theme.palette.primary.main}
            />
            {focusedLocation && focusedLocation.id === location.id ? (
              <InfoWindow
                anchor={marker}
                disableAutoPan
                maxWidth={408}
                onCloseClick={() => store.locationStore.resetFocusLocation()}
              >
                <Suspense
                  fallback={
                    <Loading message={'Loading InfoWindow Content...'} />
                  }
                >
                  <InfoWindowContent location={location} />
                </Suspense>
              </InfoWindow>
            ) : null}
          </AdvancedMarker>
        ))
      )}
    </Map>
  )
})

MapContainer.displayName = 'MapContainer'
export default MapContainer
