/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * AppointmentLimitReachedBase
 * auto generated base class for the model AppointmentLimitReachedModel.
 */
export const AppointmentLimitReachedModelBase = ModelBase
  .named('AppointmentLimitReached')
  .props({
    __typename: types.optional(types.literal("AppointmentLimitReached"), "AppointmentLimitReached"),
    message: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class AppointmentLimitReachedModelSelector extends QueryBuilder {
  get message() { return this.__attr(`message`) }
}
export function selectFromAppointmentLimitReached() {
  return new AppointmentLimitReachedModelSelector()
}

export const appointmentLimitReachedModelPrimitives = selectFromAppointmentLimitReached().message
