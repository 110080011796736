/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { SearchCoordsModel } from "./SearchCoordsModel"
import { SearchCoordsModelSelector } from "./SearchCoordsModel.base"


/**
 * MetaBase
 * auto generated base class for the model MetaModel.
 */
export const MetaModelBase = ModelBase
  .named('Meta')
  .props({
    __typename: types.optional(types.literal("Meta"), "Meta"),
    searchCoords: types.union(types.undefined, types.null, types.late(() => SearchCoordsModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class MetaModelSelector extends QueryBuilder {
  searchCoords(builder) { return this.__child(`searchCoords`, SearchCoordsModelSelector, builder) }
}
export function selectFromMeta() {
  return new MetaModelSelector()
}

export const metaModelPrimitives = selectFromMeta()
