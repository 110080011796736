import { useQuery } from '../models/reactUtils'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/system/Box'

import { DISTANCES as distances } from '../utils/helpers'
import DistanceIcon from './DistanceIcon'

const DistanceField = observer(() => {
  const { store } = useQuery()
  const { t, i18n } = useTranslation()
  const detectedLanguage = i18n.resolvedLanguage
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const defaultDistanceOption = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <DistanceIcon />
      {t('Distance')}
    </Box>
  )

  const renderValue = value => {
    if (!value) {
      return defaultDistanceOption
    }

    const selectedDistance = distances(detectedLanguage).find(
      distance => distance.value === value
    )

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DistanceIcon />
        {selectedDistance ? selectedDistance.text : ''}
      </Box>
    )
  }

  return (
    <FormControl>
      <Select
        id="distance-filter"
        displayEmpty
        disableUnderline
        variant="standard"
        value={store.searchStore.distance}
        renderValue={renderValue}
        onChange={event => store.searchStore.setDistance(event.target.value)}
        inputProps={{ 'aria-label': 'Distance Filter' }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            sx: {
              borderRadius: '8px',
            },
          },
          sx: {
            marginBlock: '0.5rem',
          },
        }}
        sx={{
          fontSize: '0.9rem',
          fontWeight: 500,
          [`& .MuiSelect-select`]: {
            pt: '6.5px',
            pb: '5px',
            pl: '8px',
            color: 'secondary.main',
            background: '#FFF',
            borderRadius: '8px',
            border: mobile ? 1 : 0,
            boxShadow: mobile ? 0 : 3,
            '&:focus': {
              borderRadius: 2,
              background: '#FFF',
            },
          },
          [`& .MuiSelect-icon`]: {
            color: 'secondary.main',
          },
        }}
      >
        <MenuItem value="" disabled>
          {defaultDistanceOption}
        </MenuItem>
        {distances(detectedLanguage).map(distance => (
          <MenuItem key={distance.value} value={distance.value}>
            {t(distance.text)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})

export default DistanceField
