import { useApiIsLoaded } from '@vis.gl/react-google-maps'
import { observer } from 'mobx-react'
import React from 'react'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Fab from '@mui/material/Fab'
import { useTheme } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useQuery } from '../models'
import FilterChipBar from './FilterChipBar'
import GooglePlacesAutocomplete from './GooglePlacesAutocomplete'
import ResultsPanel from './LazyResultsPanel'
import MapContainer from './MapContainer'
import MobileShowListButton from './MobileShowListButton'

const MainContent = () => {
  const { store } = useQuery()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [drawerOpen, toggleDrawer] = React.useState(false)
  const drawerWidth = desktop ? '408px' : 'auto'
  const searchBarExpanded = store.searchStore.searchBarExpanded
  const staticPage = store.staticPage
  const isLoaded = useApiIsLoaded()

  React.useEffect(() => {
    if (store.canShowResultsPanel && desktop) {
      toggleDrawer(true)
    }
  }, [store.canShowResultsPanel, desktop])

  return (
    <Box display="flex" aria-label="Main Content Container">
      <Drawer
        keepMounted
        disableEnforceFocus
        slotProps={{ backdrop: { invisible: true } }}
        anchor={desktop ? 'left' : 'bottom'}
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
      >
        <React.Suspense fallback={<div></div>}>
          <ResultsPanel toggleDrawer={toggleDrawer} />
        </React.Suspense>
      </Drawer>
      <React.Suspense fallback={<div></div>}>
        {mobile && !staticPage ? (
          <Box
            aria-label="Mobile Search Panel"
            boxShadow={2}
            sx={{
              position: 'absolute',
              width: '100vw',
              height: searchBarExpanded ? 184 : 140,
              bgcolor: '#FFF',
              zIndex: 1200,
            }}
          />
        ) : null}
        <Box aria-label="Search Panel">
          <Box
            aria-label="Search Bar"
            boxShadow={desktop && !drawerOpen ? 3 : 0}
            sx={{
              position: 'absolute',
              zIndex: desktop ? 1230 : 1220,
              top: 16,
              left: staticPage ? 'none' : mobile ? 'none' : 24,
              width: mobile ? 'fill-available' : 'fit-content',
              bgcolor: 'background.paper',
              borderRadius: '24px',
              border: desktop && drawerOpen ? 2 : 0,
              borderColor: desktop && drawerOpen ? 'primary.main' : 'none',
              mx: mobile ? 1.5 : 0,
            }}
          >
            {isLoaded ? <GooglePlacesAutocomplete /> : null}
          </Box>
          <Box
            aria-label="Search Filters"
            sx={{
              position: 'absolute',
              zIndex: desktop ? 1220 : 1210,
              top: mobile ? (searchBarExpanded ? 128 : 84) : 14,
              left: staticPage ? 'none' : mobile ? 'none' : 430,
              width: 'fill-available',
              overflowX: 'auto',
              webkitOverflowScrolling: 'touch',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              py: desktop ? 1 : 0,
            }}
          >
            <FilterChipBar />
          </Box>
        </Box>
      </React.Suspense>
      {store.canShowResultsPanel && desktop ? (
        <Tooltip title="Toggle Side Panel" placement="right" arrow>
          <Fab
            size="small"
            onClick={() => toggleDrawer(!drawerOpen)}
            color="primary"
            sx={{
              position: 'absolute',
              top: '50vh',
              left: drawerOpen ? '408px' : '0',
              zIndex: 1300,
              boxShadow: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              width: 24,
            }}
          >
            {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Fab>
        </Tooltip>
      ) : null}
      <React.Suspense fallback={<div></div>}>
        <MobileShowListButton isDesktop={desktop} toggleDrawer={toggleDrawer} />
      </React.Suspense>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth})` },
          ml: { sm: `${drawerOpen ? drawerWidth : 0}` },
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <MapContainer toggleDrawer={toggleDrawer} />
      </Box>
    </Box>
  )
}

export default observer(MainContent)
