import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DistanceIcon = () => {
  return (
    <SvgIcon
      color="secondary"
      sx={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '6px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
        fill="currentColor"
      >
        <path d="M480-106.001q-103.384 0-166.692-28.615-63.307-28.615-63.307-75.384 0-26.23 23.576-47.961 23.577-21.73 67.884-36.346l14.23 50.153q-21.923 8.923-33.269 16.077-11.346 7.154-21.192 19.077 4.923 19.385 61.038 35.193Q418.384-157.999 480-157.999q65.231 0 120.347-15.808T658.385-212q-12.846-11.923-24.192-18.885-11.346-6.961-30.269-14.269l14.615-50.153q44.307 14.616 67.884 36.846Q709.999-236.23 709.999-210q0 46.769-63.307 75.384Q583.384-106.001 480-106.001Zm0-221.228q19-35.154 40.808-66.77 21.808-31.616 44.27-61.847 37.769-50.154 58.846-85.5 21.077-35.346 21.077-95.654 0-69.846-47.577-117.424Q549.846-802.001 480-802.001t-117.424 47.577Q314.999-706.846 314.999-637q0 60.308 21.27 95.654 21.269 35.346 58.653 85.5 22.462 30.231 44.77 61.847Q462-362.383 480-327.229Zm0 88.92q-8.846 0-17.077-6-8.23-6-11.23-16.231-24.539-65.154-55.885-108.153-31.346-43-61.808-81.692-40.461-51.693-55.73-93.885-15.269-42.192-15.269-92.73 0-92.769 62.115-154.884Q387.231-853.999 480-853.999q92.769 0 154.884 62.115Q696.999-729.769 696.999-637q0 50.538-14.769 92.73-14.769 42.192-55.23 93.885-29.462 38.692-61.308 81.192-31.846 42.499-56.385 107.653-4 10.231-11.73 16.731-7.731 6.5-17.577 6.5Zm0-329.615q29.384 0 49.23-19.846 19.846-19.846 19.846-49.23 0-29.384-19.846-49.73-19.846-20.346-49.23-20.346-29.384 0-49.23 20.346-19.846 20.346-19.846 49.73 0 29.384 19.846 49.23 19.846 19.846 49.23 19.846ZM480-632Z" />
      </svg>
    </SvgIcon>
  )
}

export default DistanceIcon
