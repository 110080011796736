import { FeatureModelBase } from "./FeatureModel.base"


/* A graphql query fragment builders for FeatureModel */
export { selectFromFeature, featureModelPrimitives, FeatureModelSelector } from "./FeatureModel.base"

/**
 * FeatureModel
 *
 * A Feature object
 */
export const FeatureModel = FeatureModelBase
