/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * DolphinReservationBase
 * auto generated base class for the model DolphinReservationModel.
 *
 * A Dolphin reservation
 */
export const DolphinReservationModelBase = ModelBase
  .named('DolphinReservation')
  .props({
    __typename: types.optional(types.literal("DolphinReservation"), "DolphinReservation"),
    date: types.union(types.undefined, types.null, types.frozen()),
    /** 24-hour time formatted as HH:MM */
    times: types.union(types.undefined, types.null, types.array(types.union(types.null, types.string))),
    timezoneAbbr: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class DolphinReservationModelSelector extends QueryBuilder {
  get date() { return this.__attr(`date`) }
  get times() { return this.__attr(`times`) }
  get timezoneAbbr() { return this.__attr(`timezoneAbbr`) }
}
export function selectFromDolphinReservation() {
  return new DolphinReservationModelSelector()
}

export const dolphinReservationModelPrimitives = selectFromDolphinReservation().date.times.timezoneAbbr
