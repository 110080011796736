import { SpecialtyModelBase } from "./SpecialtyModel.base"


/* A graphql query fragment builders for SpecialtyModel */
export { selectFromSpecialty, specialtyModelPrimitives, SpecialtyModelSelector } from "./SpecialtyModel.base"

/**
 * SpecialtyModel
 *
 * A specialty
 */
export const SpecialtyModel = SpecialtyModelBase
