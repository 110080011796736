import { useQuery } from '../models/reactUtils'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DirectionsIcon from '@mui/icons-material/Directions'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { AppointmentButton } from './AppointmentButton'

const InfoWindowContent = observer(() => {
  const { store } = useQuery()
  const { t } = useTranslation()
  const focusedLocation = store.locationStore.focusLocation
  return (
    <Grid container sx={{ p: 1 }}>
      <Typography variant="h5" color="secondary" gutterBottom>
        {focusedLocation.displayName}
      </Typography>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid item container direction="column" width={'75%'} sx={{ mb: 1 }}>
          <Typography color="textPrimary" component="p" variant="caption">
            {focusedLocation.formattedAddress}
          </Typography>
          <Typography
            color="secondary.main"
            variant="caption"
            component="p"
            sx={{ mb: 2 }}
          >
            <Link color="inherit" href={`tel:${focusedLocation.phoneNumber}`}>
              {focusedLocation.formattedPhoneNumber}
            </Link>
          </Typography>
        </Grid>
        {focusedLocation && (
          <Grid
            item
            container
            wrap="nowrap"
            direction="column"
            alignItems="center"
            justifyContent="center"
            width={'25%'}
          >
            <Fab
              size="small"
              aria-label="Directions"
              onClick={() => window.open(focusedLocation.directionsUrl)}
              sx={{
                color: 'primary.main',
                border: '1px solid',
                bgcolor: 'transparent',
                width: 36,
                height: 36,
              }}
            >
              <DirectionsIcon />
            </Fab>
            <Typography variant="caption">{t('Directions')}</Typography>
          </Grid>
        )}
      </Grid>
      {focusedLocation.isClaimed && (
        <AppointmentButton location={focusedLocation} />
      )}
    </Grid>
  )
})

InfoWindowContent.displayName = 'InfoWindowContent'
export default InfoWindowContent
