import React from 'react'
import { createRoot } from 'react-dom/client'

import './index.css'
import App from './components/App'
import { APP_VERSION } from './constants'
import './i18n'
import './countly.js'
import './sentry.js'
import './datadog.js'


console.debug('finderCONNECT version:', APP_VERSION)
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(<App />)
