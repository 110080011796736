/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * DolphinBookingIntentBase
 * auto generated base class for the model DolphinBookingIntentModel.
 */
export const DolphinBookingIntentModelBase = ModelBase
  .named('DolphinBookingIntent')
  .props({
    __typename: types.optional(types.literal("DolphinBookingIntent"), "DolphinBookingIntent"),
    id: types.identifier,
    start: types.union(types.undefined, types.null, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class DolphinBookingIntentModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get start() { return this.__attr(`start`) }
}
export function selectFromDolphinBookingIntent() {
  return new DolphinBookingIntentModelSelector()
}

export const dolphinBookingIntentModelPrimitives = selectFromDolphinBookingIntent().start
