/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * PracticeAppointmentAnalyticsBase
 * auto generated base class for the model PracticeAppointmentAnalyticsModel.
 *
 * Appointment stats for a practice
 */
export const PracticeAppointmentAnalyticsModelBase = ModelBase
  .named('PracticeAppointmentAnalytics')
  .props({
    __typename: types.optional(types.literal("PracticeAppointmentAnalytics"), "PracticeAppointmentAnalytics"),
    appointmentsCount: types.union(types.undefined, types.null, types.integer),
    bookedAppointmentsCount: types.union(types.undefined, types.null, types.integer),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    partnerIds: types.union(types.undefined, types.null, types.array(types.union(types.null, types.string))),
    requestedAppointmentsCount: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PracticeAppointmentAnalyticsModelSelector extends QueryBuilder {
  get appointmentsCount() { return this.__attr(`appointmentsCount`) }
  get bookedAppointmentsCount() { return this.__attr(`bookedAppointmentsCount`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  get partnerIds() { return this.__attr(`partnerIds`) }
  get requestedAppointmentsCount() { return this.__attr(`requestedAppointmentsCount`) }
}
export function selectFromPracticeAppointmentAnalytics() {
  return new PracticeAppointmentAnalyticsModelSelector()
}

export const practiceAppointmentAnalyticsModelPrimitives = selectFromPracticeAppointmentAnalytics().appointmentsCount.bookedAppointmentsCount.name.partnerIds.requestedAppointmentsCount
