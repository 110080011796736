import { PartnerTierModelBase } from "./PartnerTierModel.base"
import { camelize } from 'humps'

/* A graphql query fragment builders for PartnerTierModel */
export { selectFromPartnerTier, partnerTierModelPrimitives, PartnerTierModelSelector } from "./PartnerTierModel.base"

/**
 * PartnerTierModel
 *
 * A Partner Tier
 */
export const PartnerTierModel = PartnerTierModelBase
  .views(self => ({
    get camelizedName() {
      return camelize(self.name)
    }
  }))
