import { AppointmentModelBase } from "./AppointmentModel.base"


/* A graphql query fragment builders for AppointmentModel */
export { selectFromAppointment, appointmentModelPrimitives, AppointmentModelSelector } from "./AppointmentModel.base"

/**
 * AppointmentModel
 *
 * An Appointment
 */
export const AppointmentModel = AppointmentModelBase

