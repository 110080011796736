/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * DayOfWeekMismatchBase
 * auto generated base class for the model DayOfWeekMismatchModel.
 */
export const DayOfWeekMismatchModelBase = ModelBase
  .named('DayOfWeekMismatch')
  .props({
    __typename: types.optional(types.literal("DayOfWeekMismatch"), "DayOfWeekMismatch"),
    message: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class DayOfWeekMismatchModelSelector extends QueryBuilder {
  get message() { return this.__attr(`message`) }
}
export function selectFromDayOfWeekMismatch() {
  return new DayOfWeekMismatchModelSelector()
}

export const dayOfWeekMismatchModelPrimitives = selectFromDayOfWeekMismatch().message
