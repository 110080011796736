/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FeatureModel } from "./FeatureModel"
import { FeatureModelSelector } from "./FeatureModel.base"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { PartnerPracticeModel } from "./PartnerPracticeModel"
import { PartnerPracticeModelSelector } from "./PartnerPracticeModel.base"
import { ProviderModel } from "./ProviderModel"
import { ProviderModelSelector } from "./ProviderModel.base"
import { UserModel } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"


/**
 * PracticeBase
 * auto generated base class for the model PracticeModel.
 *
 * A Practice object that describes a practice entity
 */
export const PracticeModelBase = ModelBase
  .named('Practice')
  .props({
    __typename: types.optional(types.literal("Practice"), "Practice"),
    id: types.identifier,
    isClaimed: types.union(types.undefined, types.null, types.boolean),
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    name: types.union(types.undefined, types.null, types.string),
    newCustomerFormLink: types.union(types.undefined, types.null, types.string),
    partners: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PartnerPracticeModel))))),
    planFeatures: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => FeatureModel)))),
    providers: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => ProviderModel))))),
    users: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => UserModel))))),
    websiteLink: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PracticeModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get isClaimed() { return this.__attr(`isClaimed`) }
  get name() { return this.__attr(`name`) }
  get newCustomerFormLink() { return this.__attr(`newCustomerFormLink`) }
  get websiteLink() { return this.__attr(`websiteLink`) }
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  partners(builder) { return this.__child(`partners`, PartnerPracticeModelSelector, builder) }
  planFeatures(builder) { return this.__child(`planFeatures`, FeatureModelSelector, builder) }
  providers(builder) { return this.__child(`providers`, ProviderModelSelector, builder) }
  users(builder) { return this.__child(`users`, UserModelSelector, builder) }
}
export function selectFromPractice() {
  return new PracticeModelSelector()
}

export const practiceModelPrimitives = selectFromPractice().isClaimed.name.newCustomerFormLink.websiteLink
