import { FindAProviderConfigModelBase } from "./FindAProviderConfigModel.base"


/* A graphql query fragment builders for FindAProviderConfigModel */
export { selectFromFindAProviderConfig, findAProviderConfigModelPrimitives, FindAProviderConfigModelSelector } from "./FindAProviderConfigModel.base"

/**
 * FindAProviderConfigModel
 *
 * A Partners find a provider config
 */
export const FindAProviderConfigModel = FindAProviderConfigModelBase
