/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * FindAProviderConfigBase
 * auto generated base class for the model FindAProviderConfigModel.
 *
 * A Partners find a provider config
 */
export const FindAProviderConfigModelBase = ModelBase
  .named('FindAProviderConfig')
  .props({
    __typename: types.optional(types.literal("FindAProviderConfig"), "FindAProviderConfig"),
    isAppointmentDateFilterEnabled: types.union(types.undefined, types.null, types.boolean),
    isAppointmentTimeFilterEnabled: types.union(types.undefined, types.null, types.boolean),
    isDirectionsButtonEnabled: types.union(types.undefined, types.null, types.boolean),
    isLanguageFilterEnabled: types.union(types.undefined, types.null, types.boolean),
    isLoadAllLocationsButtonEnabled: types.union(types.undefined, types.null, types.boolean),
    isLoadAllLocationsEnabled: types.union(types.undefined, types.null, types.boolean),
    isProviderNameFilterEnabled: types.union(types.undefined, types.null, types.boolean),
    isShowProviderResultsEnabled: types.union(types.undefined, types.null, types.boolean),
    isSpecialtyFilterEnabled: types.union(types.undefined, types.null, types.boolean),
    partnerTagLabel: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class FindAProviderConfigModelSelector extends QueryBuilder {
  get isAppointmentDateFilterEnabled() { return this.__attr(`isAppointmentDateFilterEnabled`) }
  get isAppointmentTimeFilterEnabled() { return this.__attr(`isAppointmentTimeFilterEnabled`) }
  get isDirectionsButtonEnabled() { return this.__attr(`isDirectionsButtonEnabled`) }
  get isLanguageFilterEnabled() { return this.__attr(`isLanguageFilterEnabled`) }
  get isLoadAllLocationsButtonEnabled() { return this.__attr(`isLoadAllLocationsButtonEnabled`) }
  get isLoadAllLocationsEnabled() { return this.__attr(`isLoadAllLocationsEnabled`) }
  get isProviderNameFilterEnabled() { return this.__attr(`isProviderNameFilterEnabled`) }
  get isShowProviderResultsEnabled() { return this.__attr(`isShowProviderResultsEnabled`) }
  get isSpecialtyFilterEnabled() { return this.__attr(`isSpecialtyFilterEnabled`) }
  get partnerTagLabel() { return this.__attr(`partnerTagLabel`) }
}
export function selectFromFindAProviderConfig() {
  return new FindAProviderConfigModelSelector()
}

export const findAProviderConfigModelPrimitives = selectFromFindAProviderConfig().isAppointmentDateFilterEnabled.isAppointmentTimeFilterEnabled.isDirectionsButtonEnabled.isLanguageFilterEnabled.isLoadAllLocationsButtonEnabled.isLoadAllLocationsEnabled.isProviderNameFilterEnabled.isShowProviderResultsEnabled.isSpecialtyFilterEnabled.partnerTagLabel
