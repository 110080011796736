/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from "mobx-state-tree"



/**
* PatientSource
*/
export const PatientSourceEnumType = types.enumeration("PatientSource", [
        "APPOINTMENT",
  "APPOINTMENT_REQUEST",
      ])
