import { toJS } from 'mobx'
import { flow, getEnv, getRoot, types } from 'mobx-state-tree'

import { getBoundingBox, radiusToZoom } from '../utils/helpers'
import {
  SEARCH_LOCATIONS,
  SEARCH_PARTNER_LOCATIONS,
  SEARCH_PARTNER_PROVIDERS,
} from './queries'

export const SearchStore = types
  .model('SearchStore', {
    providerName: '',
    specialty: 'Specialties',
    searchStarted: false,
    searchBarExpanded: false,
    searchHasNoResults: false,
    searchCoordinates: types.maybeNull(types.frozen()),
    autocompleteInputValue: types.optional(types.string, ''),
    filteredPartnerTags: types.optional(types.array(types.string), []),
    potentialUserLocation: types.maybeNull(
      types.model('Coordinates', { lat: types.number, lng: types.number })
    ),
    filteredLanguage: types.optional(types.array(types.string), []),
    distance: 15,
  })
  .views(self => ({
    get root() {
      return getRoot(self)
    },
    get providerNameFilterEnabled() {
      if (self.root.partner) {
        return self.root.partner.findAProviderConfig
          .isShowProviderResultsEnabled
      }
      return false
    },
    get partnerTagsFilterEnabled() {
      if (self.root.partner) {
        return (
          self.root.partner.hasPartnerTags &&
          self.root.partner.findAProviderConfig.partnerTagLabel !== undefined
        )
      }
      return false
    },
    get filteredPartnerTagsList() {
      return toJS(self.filteredPartnerTags)
    },
    get navigatorGeolocation() {
      if (getEnv(self).navigator && getEnv(self).navigator.geolocation) {
        return getEnv(self).navigator.geolocation
      }
      return null
    },
    get validSearchForm() {
      if (
        self.searchCoordinates !== null &&
        self.searchCoordinates !== undefined
      ) {
        return true
      }
      return false
    },
    get languageFilterEnabled() {
      if (self.root.partner) {
        return self.root.partner.findAProviderConfig.isLanguageFilterEnabled
      }
      return false
    },
    get zoomDistance() {
      return radiusToZoom(self.distance)
    },
    get boundingBox() {
      if (
        self.searchCoordinates !== null &&
        self.searchCoordinates !== undefined
      ) {
        return getBoundingBox(
          self.searchCoordinates.lat,
          self.searchCoordinates.lng,
          self.zoomDistance
        )
      }
      return null
    },
    get isProviderSearchQuery() {
      if (
        self.root.partner?.findAProviderConfig?.isShowProviderResultsEnabled
      ) {
        return SEARCH_PARTNER_PROVIDERS
      }
      return SEARCH_PARTNER_LOCATIONS
    },
    get isPartnerSearchQuery() {
      if (self.root.partner) {
        return this.isProviderSearchQuery
      }
      return SEARCH_LOCATIONS
    },
  }))
  .actions(self => ({
    setAutocompleteInputValue(value) {
      self.autocompleteInputValue = value
    },
    setPotentialUserLocation(value) {
      self.potentialUserLocation = value
    },
    setProviderName(providerName) {
      self.providerName = providerName
    },
    setSpecialty(value) {
      self.specialty = value
    },
    setFilteredLanguage(language) {
      self.filteredLanguage = language
    },
    clearFilters() {
      self.setFilterByDate('false')
      self.setFilterByTime('false')
      self.setTime(undefined)
      self.setDate(undefined)
    },
    toggleSearchStarted() {
      self.searchStarted = !self.searchStarted
    },
    toggleSearchBarExpansion() {
      self.searchBarExpanded = !self.searchBarExpanded
    },
    resetProviderName() {
      self.setProviderName('')
    },
    setFilteredPartnerTags(value) {
      self.filteredPartnerTags = value
    },
    setSearchCoordinates(value) {
      self.searchCoordinates = value
    },
    setDistance(value) {
      self.distance = value
    },
    requestUserLocation() {
      if (!self.navigatorGeolocation) {
        getEnv(self).logger.debug(
          'Geolocation is currently unavailable for this device'
        )
      } else {
        getEnv(self).logger.debug('Requesting user location')
        self.navigatorGeolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            self.setPotentialUserLocation({ lat, lng })
            self.searchWithPotentialUserLocation()
          },
          error => getEnv(self).logger.error(error),
          { enableHighAccuracy: true }
        )
      }
    },
    searchWithPotentialUserLocation() {
      if (self.potentialUserLocation) {
        const bounds = getBoundingBox(
          self.potentialUserLocation.lat,
          self.potentialUserLocation.lng,
          10
        )
        self.setSearchCoordinates(bounds)
        self.searchLocations()
      }
    },
    searchLocations: flow(function* searchLocations() {
      try {
        if (self.root.loading) {
          return undefined
        }
        self.root.setLoading(true)
        self.searchStarted = true
        self.searchHasNoResults = false
        self.root.clearLocations()
        self.root.clearProviders()

        const searchQuery = self.isPartnerSearchQuery

        const searchParams = {
          boundingBox: self.boundingBox,
        }

        if (self.specialty !== 'Specialties') {
          searchParams.specialty = self.specialty
        }

        if (self.root.partner) {
          searchParams.partnerId = self.root.partner.id
        }

        self.root.debug(`SEARCH PARAMS: ${JSON.stringify(searchParams)}`)

        const { searchLocations } = yield self.root.query(
          searchQuery,
          searchParams,
          { fetchPolicy: 'network-only' }
        )

        if (searchLocations && searchLocations.locations.length < 1) {
          self.searchHasNoResults = true
        }
      } catch (error) {
        getEnv(self).logger.error(error)
      } finally {
        self.root.setMapCenter(self.boundingBox)
        self.root.setLoading(false)
      }
    }),
  }))
