/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * PmsBookingIntentBase
 * auto generated base class for the model PmsBookingIntentModel.
 */
export const PmsBookingIntentModelBase = ModelBase
  .named('PmsBookingIntent')
  .props({
    __typename: types.optional(types.literal("PmsBookingIntent"), "PmsBookingIntent"),
    id: types.identifier,
    start: types.union(types.undefined, types.null, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PmsBookingIntentModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get start() { return this.__attr(`start`) }
}
export function selectFromPmsBookingIntent() {
  return new PmsBookingIntentModelSelector()
}

export const pmsBookingIntentModelPrimitives = selectFromPmsBookingIntent().start
