import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ShowAllLocationsIcon = () => {
  return (
    <SvgIcon
      color="secondary"
      sx={{
        userSelect: 'none',
        pointerEvents: 'none',
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_171_2)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3333 3.65551C13.6888 3.56624 14.061 3.51883 14.4445 3.51883C16.9528 3.51883 18.9815 5.54754 18.9815 8.05587C18.9815 11.4586 14.4445 16.4818 14.4445 16.4818C14.4445 16.4818 13.4625 15.3946 12.4122 13.8892L13.1528 12.6666C13.5885 13.3253 14.0409 13.9415 14.4445 14.4596C15.8185 12.7161 17.6852 9.92254 17.6852 8.05587C17.6852 6.26698 16.2334 4.81513 14.4445 4.81513L13.3333 3.65551Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4445 9.67624C15.3394 9.67624 16.0648 8.95078 16.0648 8.05587C16.0648 7.16096 15.3394 6.4355 14.4445 6.4355V9.67624Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66667 3.65551C6.31118 3.56624 5.93896 3.51883 5.55553 3.51883C3.0472 3.51883 1.01849 5.54754 1.01849 8.05587C1.01849 11.4586 5.55553 16.4818 5.55553 16.4818C5.55553 16.4818 6.53749 15.3946 7.58775 13.8892L6.84724 12.6666C6.41146 13.3253 5.9591 13.9415 5.55553 14.4596C4.18146 12.7161 2.31479 9.92254 2.31479 8.05587C2.31479 6.26698 3.76664 4.81513 5.55553 4.81513L6.66667 3.65551Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.55553 9.67624C4.66063 9.67624 3.93516 8.95078 3.93516 8.05587C3.93516 7.16096 4.66063 6.4355 5.55553 6.4355V9.67624Z"
            fill="currentColor"
          />
          <path
            d="M10 1.66699C6.77502 1.66699 4.16669 4.27533 4.16669 7.50033C4.16669 11.8753 10 18.3337 10 18.3337C10 18.3337 15.8334 11.8753 15.8334 7.50033C15.8334 4.27533 13.225 1.66699 10 1.66699ZM5.83335 7.50033C5.83335 5.20033 7.70002 3.33366 10 3.33366C12.3 3.33366 14.1667 5.20033 14.1667 7.50033C14.1667 9.90033 11.7667 13.492 10 15.7337C8.26669 13.5087 5.83335 9.87533 5.83335 7.50033Z"
            fill="currentColor"
          />
          <path
            d="M10 9.58366C11.1506 9.58366 12.0834 8.65092 12.0834 7.50033C12.0834 6.34973 11.1506 5.41699 10 5.41699C8.84943 5.41699 7.91669 6.34973 7.91669 7.50033C7.91669 8.65092 8.84943 9.58366 10 9.58366Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_171_2">
            <rect width="20" height="20" fill="#FFF" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ShowAllLocationsIcon
