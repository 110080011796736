import { types, getRoot } from 'mobx-state-tree'
import FuzzySearch from 'fuzzy-search'

export const ProviderStore = types
  .model('ProviderStore')
  .views(self => ({
    get root() {
      return getRoot(self)
    },
    get searchStore() {
      return self.root.searchStore
    },
    get providers() {
      return Array.from(self.root.providers.values())
        .sort((a, b) => {
          if (a.rank > b.rank) {
            return 1
          }
          if (a.rank < b.rank) {
            return -1
          }
          return 0
        })
    },
    get filteredProviders() {
      if (self.searchStore.providerName.length > 0) {
        const searcher = new FuzzySearch(self.providers, ['name'], { caseSensitive: false, sort: true })
        return searcher.search(self.searchStore.providerName)
      }
      return self.providers
    }
  }))
