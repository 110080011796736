export const API_URL = process.env.REACT_APP_API_URL
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY
export const GOOGLE_MAPS_ID = process.env.REACT_APP_GOOGLE_MAPS_ID
export const GOOGLE_PLACES_LIBRARY = ['maps', 'places', 'marker']
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const APP_VERSION = process.env.REACT_APP_VERSION
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
export const IMAGES_DOMAIN = `https://images.schedulewidget.com`
export const LOCIZE_API_KEY = process.env.REACT_APP_LOCIZE_API_KEY
export const LOCIZE_PROJECT_ID = process.env.REACT_APP_LOCIZE_PROJECT_ID
export const RUM_APP_ID = process.env.REACT_APP_RUM_APP_ID
export const RUM_CLIENT_TOKEN = process.env.REACT_APP_RUM_CLIENT_TOKEN
