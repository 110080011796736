import * as Sentry from '@sentry/react'
import { APP_VERSION, SENTRY_DSN, SENTRY_ENVIRONMENT } from './constants'
import { isDev } from './utils/helpers'

if (!isDev) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
  Sentry.configureScope(scope => {
    scope.setTag('origin', document.referrer)
  })
}