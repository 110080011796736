/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AppointmentModel } from "./AppointmentModel"
import { AppointmentModelSelector } from "./AppointmentModel.base"
import { PartnerTagModel } from "./PartnerTagModel"
import { PartnerTagModelSelector } from "./PartnerTagModel.base"
import { PracticeModel } from "./PracticeModel"
import { PracticeModelSelector } from "./PracticeModel.base"
import { ProviderModel } from "./ProviderModel"
import { ProviderModelSelector } from "./ProviderModel.base"


/**
 * LocationBase
 * auto generated base class for the model LocationModel.
 *
 * A Location
 */
export const LocationModelBase = ModelBase
  .named('Location')
  .props({
    __typename: types.optional(types.literal("Location"), "Location"),
    appointments: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => AppointmentModel))))),
    availableAppointments: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => AppointmentModel))))),
    bookingExperience: types.union(types.undefined, types.null, types.string),
    city: types.union(types.undefined, types.null, types.string),
    /** When use_location_names is enabled, value will be location name, if present, otherwise practice name is used. */
    displayName: types.union(types.undefined, types.null, types.string),
    dolphinBookingUrl: types.union(types.undefined, types.null, types.string),
    findAProvider: types.union(types.undefined, types.null, types.boolean),
    id: types.identifier,
    isClaimed: types.union(types.undefined, types.null, types.boolean),
    languagesSpoken: types.union(types.undefined, types.null, types.array(types.union(types.null, types.string))),
    lat: types.union(types.undefined, types.null, types.string),
    lng: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    partnerTags: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PartnerTagModel))))),
    phoneNumber: types.union(types.undefined, types.null, types.string),
    practice: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PracticeModel))),
    providers: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => ProviderModel))))),
    state: types.union(types.undefined, types.null, types.string),
    street: types.union(types.undefined, types.null, types.string),
    timezone: types.union(types.undefined, types.null, types.string),
    yapiBookingUrl: types.union(types.undefined, types.null, types.string),
    yapiIntegrationEnabled: types.union(types.undefined, types.null, types.boolean),
    zip: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class LocationModelSelector extends QueryBuilder {
  get bookingExperience() { return this.__attr(`bookingExperience`) }
  get city() { return this.__attr(`city`) }
  get displayName() { return this.__attr(`displayName`) }
  get dolphinBookingUrl() { return this.__attr(`dolphinBookingUrl`) }
  get findAProvider() { return this.__attr(`findAProvider`) }
  get id() { return this.__attr(`id`) }
  get isClaimed() { return this.__attr(`isClaimed`) }
  get languagesSpoken() { return this.__attr(`languagesSpoken`) }
  get lat() { return this.__attr(`lat`) }
  get lng() { return this.__attr(`lng`) }
  get name() { return this.__attr(`name`) }
  get phoneNumber() { return this.__attr(`phoneNumber`) }
  get state() { return this.__attr(`state`) }
  get street() { return this.__attr(`street`) }
  get timezone() { return this.__attr(`timezone`) }
  get yapiBookingUrl() { return this.__attr(`yapiBookingUrl`) }
  get yapiIntegrationEnabled() { return this.__attr(`yapiIntegrationEnabled`) }
  get zip() { return this.__attr(`zip`) }
  appointments(builder, args) { return this.__child(`appointments` + (args ? '(' + ['fromDate', 'toDate'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), AppointmentModelSelector, builder) }
  availableAppointments(builder) { return this.__child(`availableAppointments`, AppointmentModelSelector, builder) }
  partnerTags(builder) { return this.__child(`partnerTags`, PartnerTagModelSelector, builder) }
  practice(builder) { return this.__child(`practice`, PracticeModelSelector, builder) }
  providers(builder) { return this.__child(`providers`, ProviderModelSelector, builder) }
}
export function selectFromLocation() {
  return new LocationModelSelector()
}

export const locationModelPrimitives = selectFromLocation().bookingExperience.city.displayName.dolphinBookingUrl.findAProvider.isClaimed.languagesSpoken.lat.lng.name.phoneNumber.state.street.timezone.yapiBookingUrl.yapiIntegrationEnabled.zip
