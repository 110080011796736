import gql from 'graphql-tag'

const locationFragment = gql`
  fragment locationFields on Location {
    __typename
    id
    isClaimed
    name
    street
    city
    state
    zip
    timezone
    phoneNumber
    lat
    lng
    dolphinBookingUrl
    languagesSpoken
    displayName
    bookingExperience
  }
`
const practiceFragment = gql`
  fragment practiceFields on Practice {
    __typename
    id
    name
    isClaimed
  }
`
const providerFragment = gql`
  fragment providerFields on Provider {
    __typename
    bio
    chewsiIsActive
    id
    name
    newCustomerFormLink
    photoS3Key
    websiteLink
    languagesSpoken
  }
`
const partnerTierFragment = gql`
  fragment partnerTierFields on Provider {
    partnerTier(partnerId: $partnerId) {
      __typename
      name
      description
      hexColor
      rank
    }
  }
`
export const LOCATION = gql`
  query LocationQuery($id: ID!) {
    location(id: $id) {
      ...locationFields
      practice {
        ...practiceFields
      }
      providers {
        ...providerFields
        specialties {
          __typename
          name
        }
      }
      partnerTags {
        description
        hexColor
        id
        name
      }
    }
  }
  ${locationFragment}
  ${practiceFragment}
  ${providerFragment}
`
export const PRACTICE = gql`
  query PracticeQuery($id: ID!) {
    practice(id: $id) {
      ...practiceFields
      locations {
        ...locationFields
        practice {
          ...practiceFields
        }
        providers {
          ...providerFields
          specialties {
            __typename
            name
          }
        }
      }
    }
  }
  ${practiceFragment}
  ${locationFragment}
  ${providerFragment}
`
export const PARTNER = gql`
  query PartnerQuery($id: ID!) {
    partner(id: $id) {
      __typename
      id
      name
      findAProviderConfig {
        __typename
        isAppointmentDateFilterEnabled
        isAppointmentTimeFilterEnabled
        isDirectionsButtonEnabled
        isProviderNameFilterEnabled
        isSpecialtyFilterEnabled
        isLanguageFilterEnabled
        isLoadAllLocationsEnabled
        isLoadAllLocationsButtonEnabled
        isShowProviderResultsEnabled
        partnerTagLabel
      }
      partnerTags {
        __typename
        id
        name
        description
        hexColor
      }
    }
  }
`
export const PARTNER_LOCATIONS = gql`
  query PartnerLocationsQuery($id: ID!) {
    partner(id: $id) {
      __typename
      id
      locations {
        ...locationFields
        partnerTags {
          __typename
          id
          name
          description
          hexColor
        }
        practice {
          ...practiceFields
        }
        providers {
          ...providerFields
          locations {
            ...locationFields
            practice {
              ...practiceFields
            }
          }
          specialties {
            __typename
            name
          }
          partnerTier(partnerId: $id) {
            __typename
            name
            hexColor
            rank
            description
          }
          locations {
            __typename
            id
          }
        }
      }
    }
  }
  ${locationFragment}
  ${practiceFragment}
  ${providerFragment}
`
export const SPECIALTIES = gql`
  query Specialties {
    specialties {
      __typename
      name
    }
  }
`
export const SEARCH_LOCATIONS = gql`
  query SearchLocations($boundingBox: BoundingBox!, $specialty: String) {
    searchLocations(boundingBox: $boundingBox, specialty: $specialty) {
      locations {
        ...locationFields
        practice {
          ...practiceFields
        }
        providers {
          ...providerFields
          specialties {
            __typename
            name
          }
        }
      }
    }
  }
  ${locationFragment}
  ${practiceFragment}
  ${providerFragment}
`
export const SEARCH_PARTNER_LOCATIONS = gql`
  query SearchPartnerLocations(
    $boundingBox: BoundingBox!
    $specialty: String
    $partnerId: ID
  ) {
    searchLocations(
      boundingBox: $boundingBox
      specialty: $specialty
      partnerId: $partnerId
    ) {
      locations {
        ...locationFields
        partnerTags {
          __typename
          id
        }
        practice {
          ...practiceFields
        }
        providers {
          ...providerFields
          specialties {
            __typename
            name
          }
          ...partnerTierFields
        }
      }
    }
  }
  ${locationFragment}
  ${practiceFragment}
  ${providerFragment}
  ${partnerTierFragment}
`
export const SEARCH_PARTNER_PROVIDERS = gql`
  query SearchPartnerLocations(
    $boundingBox: BoundingBox!
    $specialty: String
    $partnerId: ID
  ) {
    searchLocations(
      boundingBox: $boundingBox
      specialty: $specialty
      partnerId: $partnerId
    ) {
      locations {
        ...locationFields
        partnerTags {
          __typename
          id
        }
        practice {
          ...practiceFields
        }
        providers {
          ...providerFields
          locations {
            ...locationFields
            practice {
              ...practiceFields
            }
          }
          specialties {
            __typename
            name
          }
          ...partnerTierFields
        }
      }
    }
  }
  ${locationFragment}
  ${practiceFragment}
  ${providerFragment}
  ${partnerTierFragment}
`
