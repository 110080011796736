import { useQuery } from '../models/reactUtils'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Fab from '@mui/material/Fab'

export const AppointmentButton = observer(({ location, provider }) => {
  const { store } = useQuery()
  const { t } = useTranslation()
  const buttonText = () => {
    switch (location?.bookingExperience) {
      case 'dolphin':
      case 'schedule_connect':
        return t('Book Online')
      case 'intelibly_direct':
        return t('Request Appointment')
      default:
        return null
    }
  }
  const onClickHandler = () => {
    if (provider && provider.id) {
      store.openProviderLocation(provider.id, location.id)
    } else {
      store.openPracticeLocation(location.practice.id, location.id)
    }
  }

  return (
    <Fab
      aria-label="Appointment Button"
      size="small"
      variant="extended"
      sx={{
        my: 1,
        width: 1,
        color: 'primary.main',
        bgcolor: 'transparent',
        border: '1px solid',
        borderColor: 'primary.main',
      }}
      onClick={onClickHandler}
    >
      {buttonText()}
    </Fab>
  )
})
