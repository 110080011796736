import { FindAProviderModelBase } from "./FindAProviderModel.base"


/* A graphql query fragment builders for FindAProviderModel */
export { selectFromFindAProvider, findAProviderModelPrimitives, FindAProviderModelSelector } from "./FindAProviderModel.base"

/**
 * FindAProviderModel
 *
 * Find-a-provider
 */
export const FindAProviderModel = FindAProviderModelBase
