import React from 'react'
import { observer } from 'mobx-react'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import ErrorIcon from '@mui/icons-material/Error'
import { useQuery } from '../models/reactUtils'
import { Typography } from '@mui/material'

const Alert = () => {
  const { store } = useQuery()
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={!!store.errorMessage}
      autoHideDuration={6000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      onClose={store.resetErrorMessage}
    >
      <SnackbarContent
        message={
          <Typography id="message-id" alignItems="center" display="flex">
            <ErrorIcon fontSize="small" sx={{ mr: 1 }} />
            {store.errorMessage}
          </Typography>
        }
        sx={{ bgcolor: 'error.dark' }}
      />
    </Snackbar>
  )
}

export default observer(Alert)
