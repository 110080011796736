import { RootMutationTypeModelBase } from "./RootMutationTypeModel.base"


/* A graphql query fragment builders for RootMutationTypeModel */
export { selectFromRootMutationType, rootMutationTypeModelPrimitives, RootMutationTypeModelSelector } from "./RootMutationTypeModel.base"

/**
 * RootMutationTypeModel
 */
export const RootMutationTypeModel = RootMutationTypeModelBase
