/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { QueryBuilder } from "mst-gql"
import { AppointmentLimitReachedModelType } from "./AppointmentLimitReachedModel"
import { AppointmentLimitReachedModelSelector, appointmentLimitReachedModelPrimitives } from "./AppointmentLimitReachedModel.base"
import { DolphinBookingIntentModelType } from "./DolphinBookingIntentModel"
import { DolphinBookingIntentModelSelector, dolphinBookingIntentModelPrimitives } from "./DolphinBookingIntentModel.base"
import { ReservationUnavailableModelType } from "./ReservationUnavailableModel"
import { ReservationUnavailableModelSelector, reservationUnavailableModelPrimitives } from "./ReservationUnavailableModel.base"
import { UserInputValidationFailedModelType } from "./UserInputValidationFailedModel"
import { UserInputValidationFailedModelSelector, userInputValidationFailedModelPrimitives } from "./UserInputValidationFailedModel.base"

export class BookDolphinAppointmentResultModelSelector extends QueryBuilder {
  userInputValidationFailed(builder) { return this.__inlineFragment(`UserInputValidationFailed`, UserInputValidationFailedModelSelector, builder) }
  reservationUnavailable(builder) { return this.__inlineFragment(`ReservationUnavailable`, ReservationUnavailableModelSelector, builder) }
  dolphinBookingIntent(builder) { return this.__inlineFragment(`DolphinBookingIntent`, DolphinBookingIntentModelSelector, builder) }
  appointmentLimitReached(builder) { return this.__inlineFragment(`AppointmentLimitReached`, AppointmentLimitReachedModelSelector, builder) }
}
export function selectFromBookDolphinAppointmentResult() {
  return new BookDolphinAppointmentResultModelSelector()
}

// provides all primitive fields of union member types combined together
export const bookDolphinAppointmentResultModelPrimitives = selectFromBookDolphinAppointmentResult().userInputValidationFailed(userInputValidationFailedModelPrimitives).reservationUnavailable(reservationUnavailableModelPrimitives).dolphinBookingIntent(dolphinBookingIntentModelPrimitives).appointmentLimitReached(appointmentLimitReachedModelPrimitives)