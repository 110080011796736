import { ProviderModelBase } from './ProviderModel.base'
import { IMAGES_DOMAIN } from '../constants'

/* A graphql query fragment builders for ProviderModel */
export {
  selectFromProvider,
  providerModelPrimitives,
  ProviderModelSelector,
} from './ProviderModel.base'

/**
 * ProviderModel
 *
 * A Provider
 */
export const ProviderModel = ProviderModelBase.props({
  activeAccordion: false,
})
  .views(self => ({
    get specialtyList() {
      return self.specialties.map(specialty => specialty.name).join(', ')
    },
    get photoUrl() {
      if (self.photoS3Key) {
        return `${IMAGES_DOMAIN}/fit-in/128x128/${self.photoS3Key}`
      }
      return ''
    },
    get formattedLanguagesSpoken() {
      if (self.languagesSpoken) {
        return self.languagesSpoken.join(', ')
      }
      return ''
    },
    get rank() {
      if (self.partnerTier) {
        return self.partnerTier.rank
      }
      /**
       * Artificial rank to push unranked providers down in the sort list
       */
      return 1000
    },
  }))
  .actions(self => ({
    toggleAccordion(active) {
      self.activeAccordion = active || !self.activeAccordion
    },
  }))
