import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SpecialtyIcon = () => {
  return (
    <SvgIcon
      color="secondary"
      sx={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '6px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
        fill="currentColor"
      >
        <path d="M663.795-827.999q54.281.385 91.319 37.933 37.038 37.547 37.038 90.838 0 9.459-.808 25.689-.807 16.231-3.652 38.385l-52.54 378.461q-3.846 30.692-27.076 46.692-23.231 16-48.316 16-16.145 0-30.491-7.923-14.345-7.924-24.422-24.231l-104.77-150.693q-4.154-5.769-9.038-8.73-4.885-2.962-11.039-2.962-4.539 0-20.077 11.692l-102.77 148.693q-11.077 16.538-26.422 25.346-15.346 8.808-31.491 8.808-25.085 0-47.816-16-22.73-16-26.576-46.692l-52.49-378.408q-2.895-22.207-3.702-38.438-.808-16.23-.808-25.689 0-53.291 37.038-91.031 37.038-37.74 90.96-37.74 29.539 0 49.618 7.647 20.079 7.646 39.115 16.5 19.036 8.853 40.724 16.353 21.689 7.5 54.697 7.5 34.615 0 56.385-7.5 21.769-7.5 39.769-16.5t38.017-16.5q20.017-7.5 49.624-7.5Zm.847 52.999q-21.962 0-38.673 7.646-16.71 7.647-36.378 16.5Q569.923-742 544.637-734.5q-25.287 7.5-64.693 7.5-38.636 0-64.049-7.6-25.412-7.6-44.664-16.4-19.678-8.8-36.781-16.4-17.104-7.6-39.077-7.6-31.527 0-53.527 22t-22 54.195q0 7.805 1 21.305t3 32.5l53.036 380.484q.964 7.516 6.964 12.016t14 4.5q6.154 0 10.731-3.481t7.731-8.135l102.155-148.077q12.076-16.923 27.655-25.384 15.58-8.462 33.808-8.462 17.843 0 34.189 8.962 16.346 8.961 28.422 25.884L645.74-257.425q3.045 4.059 6.46 7.242t8.954 3.183q8 0 14.5-4.5t7.5-12.5l53-380q2.4-18.966 3.2-33.19.8-14.224.8-21.81 0-32-22-54t-53.512-22ZM480-511Z" />
      </svg>
    </SvgIcon>
  )
}

export default SpecialtyIcon
