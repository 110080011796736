/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from "mobx-state-tree"



/**
* Locale
*/
export const LocaleEnumType = types.enumeration("Locale", [
        "EN",
  "ES",
  "FR",
      ])
