import { PartnerModelBase } from './PartnerModel.base'
import { PARTNER_LOCATIONS } from './queries'

/* A graphql query fragment builders for PartnerModel */
export {
  selectFromPartner,
  partnerModelPrimitives,
  PartnerModelSelector,
} from './PartnerModel.base'

/**
 * PartnerModel
 *
 * A Partner object that describes a partner entity
 */
export const PartnerModel = PartnerModelBase.views(self => ({
  get hasPartnerTags() {
    return self.partnerTags && self.partnerTags.length > 0
  },
})).actions(self => ({
  maybeFetchAllLocations() {
    self.store.debug('CHECKING IF LOCATIONS NEED TO BE LOADED FOR PARTNER')
    if (self.store.loading) {
      return undefined
    }
    if (self.findAProviderConfig.isLoadAllLocationsButtonEnabled) {
      self.store.debug(`LOAD ALL LOCATIONS IS ENABLED FOR PARTNER ${self.id}`)
      const query = self.store.query(
        PARTNER_LOCATIONS,
        { id: self.id },
        { queryCache: 'network-only' }
      )
      if (query.loading) {
        self.store.setLoading(true)
        self.store.debug(`LOADING ALL LOCATIONS FOR PARTNER ${self.id}`)
      }
      query.then(
        () => {
          self.store.debug(`FINISHED LOADING ALL LOCATIONS FOR ${self.id}`)
          self.store.setLoading(false)
        },
        error => {
          self.store.error(error)
          self.store.setLoading(false)
        }
      )
    } else {
      self.store.debug('LOAD ALL LOCATIONS IS NOT ENABLED FOR PARTNER')
    }
  },
}))
