/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * PmsAvailableAppointmentBase
 * auto generated base class for the model PmsAvailableAppointmentModel.
 *
 * An available appointment for practice's PMS integration
 */
export const PmsAvailableAppointmentModelBase = ModelBase
  .named('PmsAvailableAppointment')
  .props({
    __typename: types.optional(types.literal("PmsAvailableAppointment"), "PmsAvailableAppointment"),
    availabilitySlotId: types.identifier,
    date: types.union(types.undefined, types.null, types.frozen()),
    endTime: types.union(types.undefined, types.null, types.frozen()),
    startTime: types.union(types.undefined, types.null, types.frozen()),
    timezoneAbbr: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PmsAvailableAppointmentModelSelector extends QueryBuilder {
  get availabilitySlotId() { return this.__attr(`availabilitySlotId`) }
  get date() { return this.__attr(`date`) }
  get endTime() { return this.__attr(`endTime`) }
  get startTime() { return this.__attr(`startTime`) }
  get timezoneAbbr() { return this.__attr(`timezoneAbbr`) }
}
export function selectFromPmsAvailableAppointment() {
  return new PmsAvailableAppointmentModelSelector()
}

export const pmsAvailableAppointmentModelPrimitives = selectFromPmsAvailableAppointment().availabilitySlotId.date.endTime.startTime.timezoneAbbr
