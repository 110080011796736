/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * PatientBase
 * auto generated base class for the model PatientModel.
 *
 * A Patient
 */
export const PatientModelBase = ModelBase
  .named('Patient')
  .props({
    __typename: types.optional(types.literal("Patient"), "Patient"),
    comment: types.union(types.undefined, types.null, types.string),
    email: types.union(types.undefined, types.string),
    firstName: types.union(types.undefined, types.string),
    lastName: types.union(types.undefined, types.string),
    phone: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PatientModelSelector extends QueryBuilder {
  get comment() { return this.__attr(`comment`) }
  get email() { return this.__attr(`email`) }
  get firstName() { return this.__attr(`firstName`) }
  get lastName() { return this.__attr(`lastName`) }
  get phone() { return this.__attr(`phone`) }
}
export function selectFromPatient() {
  return new PatientModelSelector()
}

export const patientModelPrimitives = selectFromPatient().comment.email.firstName.lastName.phone
