/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"


/**
 * PartnerPracticeBase
 * auto generated base class for the model PartnerPracticeModel.
 *
 * A Partner Practice relation object that describes a many to many relationship between a partner and a practice entity
 */
export const PartnerPracticeModelBase = ModelBase
  .named('PartnerPractice')
  .props({
    __typename: types.optional(types.literal("PartnerPractice"), "PartnerPractice"),
    gaTrackingCode: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PartnerPracticeModelSelector extends QueryBuilder {
  get gaTrackingCode() { return this.__attr(`gaTrackingCode`) }
  get id() { return this.__attr(`id`) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
}
export function selectFromPartnerPractice() {
  return new PartnerPracticeModelSelector()
}

export const partnerPracticeModelPrimitives = selectFromPartnerPractice().gaTrackingCode
