import * as Sentry from '@sentry/browser'
import qs from 'qs'

export const logger = {
  get isProd() {
    return process.env.NODE_ENV === 'production'
  },
  get isDebugMode() {
    return (
      qs.parse(window.location.search) &&
      qs.parse(window.location.search, { ignoreQueryPrefix: true }).debug ===
      'true'
    )
  },
  get isTestMode() {
    return process.env.NODE_ENV === 'test'
  },
  error(error) {
    this.isProd ? Sentry.captureException(error) : console.error(error) // eslint-disable-line
  },
  log(log) {
    this.isProd ? Sentry.captureMessage(log) : console.log(log) // eslint-disable-line
  },
  debug(debug) {
    if (this.isTestMode) return undefined
    return (this.isProd && !this.isDebugMode) ? undefined : console.debug(debug)
  },
}
