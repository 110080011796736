import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const Loading = ({ message }) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      position="absolute"
      top="50%"
      left="50%"
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      <CircularProgress />
      <Typography variant="h6">{message}</Typography>
    </Grid>
  )
}

Loading.propTypes = {
  message: PropTypes.string,
}

export default Loading
