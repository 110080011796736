import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const LanugageIcon = () => {
  return (
    <SvgIcon
      color="secondary"
      sx={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '6px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
        fill="currentColor"
      >
        <path d="M480-116.001q-75.154 0-141.499-28.462-66.346-28.461-115.962-78.076-49.615-49.616-78.076-115.962Q116.001-404.846 116.001-480q0-75.769 28.462-141.807 28.461-66.038 78.076-115.654 49.616-49.615 115.962-78.076Q404.846-843.999 480-843.999q75.769 0 141.807 28.462 66.038 28.461 115.654 78.076 49.615 49.616 78.076 115.654Q843.999-555.769 843.999-480q0 75.154-28.462 141.499-28.461 66.346-78.076 115.962-49.616 49.615-115.654 78.076Q555.769-116.001 480-116.001Zm0-53.845q21.616-21.616 40.539-70.424 18.923-48.808 29.077-108.808H410.384q10.923 61.539 29.462 110.347 18.538 48.808 40.154 68.885Zm-64.46-5q-19-30-34.308-75.539t-23.462-98.693H197.076q31.693 69.309 89.501 115.194 57.809 45.885 128.963 59.038Zm128.92 0q71.154-13.153 128.963-59.038 57.808-45.885 89.501-115.194H602.23Q592.153-295.539 576.845-250q-15.308 45.539-32.385 75.154Zm-365.537-226.23h173.693q-2.769-21.308-3.461-41.731-.692-20.424-.692-39.193t.692-38.193q.692-19.423 3.461-38.731H178.923q-5.769 19.385-8.346 39.385Q168-499.539 168-480t2.577 39.539q2.577 20 8.346 39.385Zm226.692 0h148.77q2.769-22.308 3.462-41.347.692-19.038.692-37.577 0-18.539-.692-38.077-.693-19.539-3.462-40.847h-148.77q-2.769 21.308-3.462 40.847-.692 19.538-.692 38.077 0 18.539.692 38.077.693 19.539 3.462 40.847Zm201.769 0h173.693q5.769-19.385 8.346-39.385Q792-460.461 792-480t-2.577-40.039q-2.577-20.5-8.346-38.885H607.384q2.769 21.308 3.461 41.731.692 20.424.692 39.193t-.692 38.193q-.692 19.423-3.461 38.731Zm-5.154-209.846h160.694Q730.846-681 674-726.116q-56.847-45.115-129.54-59.423 19 31.923 33.923 76.885 14.924 44.962 23.847 97.732Zm-191.846 0h139.232q-10.923-61.154-30.039-110.924-19.115-49.769-39.577-68.308-20.462 18.539-39.577 68.308-19.116 49.77-30.039 110.924Zm-213.308 0H357.77q8.923-52.77 23.847-97.732 14.923-44.962 33.923-76.885-73.077 14.308-129.732 59.616-56.654 45.308-88.732 115.001Z" />
      </svg>
    </SvgIcon>
  )
}

export default LanugageIcon
