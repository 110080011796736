/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FieldErrorModel } from "./FieldErrorModel"


/**
 * FieldErrorBase
 * auto generated base class for the model FieldErrorModel.
 */
export const FieldErrorModelBase = ModelBase
  .named('FieldError')
  .props({
    __typename: types.optional(types.literal("FieldError"), "FieldError"),
    errors: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => FieldErrorModel))))),
    field: types.union(types.undefined, types.null, types.string),
    message: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class FieldErrorModelSelector extends QueryBuilder {
  get field() { return this.__attr(`field`) }
  get message() { return this.__attr(`message`) }
  errors(builder) { return this.__child(`errors`, FieldErrorModelSelector, builder) }
}
export function selectFromFieldError() {
  return new FieldErrorModelSelector()
}

export const fieldErrorModelPrimitives = selectFromFieldError().field.message
