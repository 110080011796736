/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * FeatureBase
 * auto generated base class for the model FeatureModel.
 *
 * A Feature object
 */
export const FeatureModelBase = ModelBase
  .named('Feature')
  .props({
    __typename: types.optional(types.literal("Feature"), "Feature"),
    description: types.union(types.undefined, types.null, types.string),
    key: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class FeatureModelSelector extends QueryBuilder {
  get description() { return this.__attr(`description`) }
  get key() { return this.__attr(`key`) }
}
export function selectFromFeature() {
  return new FeatureModelSelector()
}

export const featureModelPrimitives = selectFromFeature().description.key
