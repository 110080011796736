/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"


/**
 * SearchLocationsBase
 * auto generated base class for the model SearchLocationsModel.
 *
 * Search locations results
 */
export const SearchLocationsModelBase = ModelBase
  .named('SearchLocations')
  .props({
    __typename: types.optional(types.literal("SearchLocations"), "SearchLocations"),
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class SearchLocationsModelSelector extends QueryBuilder {
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
}
export function selectFromSearchLocations() {
  return new SearchLocationsModelSelector()
}

export const searchLocationsModelPrimitives = selectFromSearchLocations()
