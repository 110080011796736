/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * SearchCoordsBase
 * auto generated base class for the model SearchCoordsModel.
 */
export const SearchCoordsModelBase = ModelBase
  .named('SearchCoords')
  .props({
    __typename: types.optional(types.literal("SearchCoords"), "SearchCoords"),
    lat: types.union(types.undefined, types.null, types.string),
    lng: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class SearchCoordsModelSelector extends QueryBuilder {
  get lat() { return this.__attr(`lat`) }
  get lng() { return this.__attr(`lng`) }
}
export function selectFromSearchCoords() {
  return new SearchCoordsModelSelector()
}

export const searchCoordsModelPrimitives = selectFromSearchCoords().lat.lng
