import { ProviderAppointmentAnalyticsModelBase } from "./ProviderAppointmentAnalyticsModel.base"


/* A graphql query fragment builders for ProviderAppointmentAnalyticsModel */
export { selectFromProviderAppointmentAnalytics, providerAppointmentAnalyticsModelPrimitives, ProviderAppointmentAnalyticsModelSelector } from "./ProviderAppointmentAnalyticsModel.base"

/**
 * ProviderAppointmentAnalyticsModel
 *
 * Appointment stats for a provider
 */
export const ProviderAppointmentAnalyticsModel = ProviderAppointmentAnalyticsModelBase
