import { useQuery } from '../models/reactUtils'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ShowAllLocationsIcon from './ShowAllLocationsIcon'

const ShowAllLocationsButton = observer(() => {
  const { store } = useQuery()
  const { t } = useTranslation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const loading = store.loading

  if (!store.loadAllLocationsButtonEnabled) {
    return null
  }
  return (
    <Box
      boxShadow={mobile ? 0 : 3}
      sx={{
        bgcolor: '#fff',
        borderRadius: '8px',
        border: mobile ? 1 : 0,
        borderColor: mobile ? 'secondary.main' : 'transparent',
      }}
    >
      <Chip
        aria-label="Show All Locations"
        label={t('Show All Locations')}
        disabled={loading}
        icon={<ShowAllLocationsIcon />}
        sx={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          height: '36px',
          color: 'secondary.main',
          fontSize: '0.9rem',
          fontWeight: 500,
          pl: '8px',
          [`& .MuiChip-label`]: {
            pl: '8px',
          },
        }}
        onClick={store.showAllLocations}
        clickable
      />
    </Box>
  )
})

export default ShowAllLocationsButton
