/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin } from "mst-gql"

import { FindAProviderConfigModel } from "./FindAProviderConfigModel"
import { findAProviderConfigModelPrimitives, FindAProviderConfigModelSelector } from "./FindAProviderConfigModel.base"
import { ProviderModel } from "./ProviderModel"
import { providerModelPrimitives, ProviderModelSelector } from "./ProviderModel.base"
import { DolphinReservationModel } from "./DolphinReservationModel"
import { dolphinReservationModelPrimitives, DolphinReservationModelSelector } from "./DolphinReservationModel.base"
import { SpecialtyModel } from "./SpecialtyModel"
import { specialtyModelPrimitives, SpecialtyModelSelector } from "./SpecialtyModel.base"
import { AppointmentModel } from "./AppointmentModel"
import { appointmentModelPrimitives, AppointmentModelSelector } from "./AppointmentModel.base"
import { FeatureModel } from "./FeatureModel"
import { featureModelPrimitives, FeatureModelSelector } from "./FeatureModel.base"
import { PartnerTagModel } from "./PartnerTagModel"
import { partnerTagModelPrimitives, PartnerTagModelSelector } from "./PartnerTagModel.base"
import { PartnerPracticeModel } from "./PartnerPracticeModel"
import { partnerPracticeModelPrimitives, PartnerPracticeModelSelector } from "./PartnerPracticeModel.base"
import { PmsBookingIntentModel } from "./PmsBookingIntentModel"
import { pmsBookingIntentModelPrimitives, PmsBookingIntentModelSelector } from "./PmsBookingIntentModel.base"
import { UserInputValidationFailedModel } from "./UserInputValidationFailedModel"
import { userInputValidationFailedModelPrimitives, UserInputValidationFailedModelSelector } from "./UserInputValidationFailedModel.base"
import { NotificationModel } from "./NotificationModel"
import { notificationModelPrimitives, NotificationModelSelector } from "./NotificationModel.base"
import { AppointmentUnavailableModel } from "./AppointmentUnavailableModel"
import { appointmentUnavailableModelPrimitives, AppointmentUnavailableModelSelector } from "./AppointmentUnavailableModel.base"
import { RootQueryTypeModel } from "./RootQueryTypeModel"
import { rootQueryTypeModelPrimitives, RootQueryTypeModelSelector } from "./RootQueryTypeModel.base"
import { AppointmentRequestModel } from "./AppointmentRequestModel"
import { appointmentRequestModelPrimitives, AppointmentRequestModelSelector } from "./AppointmentRequestModel.base"
import { ReservationUnavailableModel } from "./ReservationUnavailableModel"
import { reservationUnavailableModelPrimitives, ReservationUnavailableModelSelector } from "./ReservationUnavailableModel.base"
import { PracticeAppointmentAnalyticsModel } from "./PracticeAppointmentAnalyticsModel"
import { practiceAppointmentAnalyticsModelPrimitives, PracticeAppointmentAnalyticsModelSelector } from "./PracticeAppointmentAnalyticsModel.base"
import { FieldErrorModel } from "./FieldErrorModel"
import { fieldErrorModelPrimitives, FieldErrorModelSelector } from "./FieldErrorModel.base"
import { DayOfWeekMismatchModel } from "./DayOfWeekMismatchModel"
import { dayOfWeekMismatchModelPrimitives, DayOfWeekMismatchModelSelector } from "./DayOfWeekMismatchModel.base"
import { AppointmentTypeModel } from "./AppointmentTypeModel"
import { appointmentTypeModelPrimitives, AppointmentTypeModelSelector } from "./AppointmentTypeModel.base"
import { DolphinBookingIntentModel } from "./DolphinBookingIntentModel"
import { dolphinBookingIntentModelPrimitives, DolphinBookingIntentModelSelector } from "./DolphinBookingIntentModel.base"
import { PracticeModel } from "./PracticeModel"
import { practiceModelPrimitives, PracticeModelSelector } from "./PracticeModel.base"
import { MetaModel } from "./MetaModel"
import { metaModelPrimitives, MetaModelSelector } from "./MetaModel.base"
import { LocationModel } from "./LocationModel"
import { locationModelPrimitives, LocationModelSelector } from "./LocationModel.base"
import { PatientModel } from "./PatientModel"
import { patientModelPrimitives, PatientModelSelector } from "./PatientModel.base"
import { ProviderAppointmentAnalyticsModel } from "./ProviderAppointmentAnalyticsModel"
import { providerAppointmentAnalyticsModelPrimitives, ProviderAppointmentAnalyticsModelSelector } from "./ProviderAppointmentAnalyticsModel.base"
import { SearchCoordsModel } from "./SearchCoordsModel"
import { searchCoordsModelPrimitives, SearchCoordsModelSelector } from "./SearchCoordsModel.base"
import { PartnerModel } from "./PartnerModel"
import { partnerModelPrimitives, PartnerModelSelector } from "./PartnerModel.base"
import { SearchLocationsModel } from "./SearchLocationsModel"
import { searchLocationsModelPrimitives, SearchLocationsModelSelector } from "./SearchLocationsModel.base"
import { PartnerTierModel } from "./PartnerTierModel"
import { partnerTierModelPrimitives, PartnerTierModelSelector } from "./PartnerTierModel.base"
import { AppointmentLimitReachedModel } from "./AppointmentLimitReachedModel"
import { appointmentLimitReachedModelPrimitives, AppointmentLimitReachedModelSelector } from "./AppointmentLimitReachedModel.base"
import { UserModel } from "./UserModel"
import { userModelPrimitives, UserModelSelector } from "./UserModel.base"
import { FindAProviderModel } from "./FindAProviderModel"
import { findAProviderModelPrimitives, FindAProviderModelSelector } from "./FindAProviderModel.base"
import { PmsAvailableAppointmentModel } from "./PmsAvailableAppointmentModel"
import { pmsAvailableAppointmentModelPrimitives, PmsAvailableAppointmentModelSelector } from "./PmsAvailableAppointmentModel.base"
import { RootMutationTypeModel } from "./RootMutationTypeModel"
import { rootMutationTypeModelPrimitives, RootMutationTypeModelSelector } from "./RootMutationTypeModel.base"

import { bookPmsAppointmentResultModelPrimitives, BookPmsAppointmentResultModelSelector  } from "./"
import { bookDolphinAppointmentResultModelPrimitives, BookDolphinAppointmentResultModelSelector  } from "./"






/**
* Store, managing, among others, all the objects received through graphQL
*/
export const RootStoreBase = MSTGQLStore
  .named("RootStore")
  .extend(configureStoreMixin([['FindAProviderConfig', () => FindAProviderConfigModel], ['Provider', () => ProviderModel], ['DolphinReservation', () => DolphinReservationModel], ['Specialty', () => SpecialtyModel], ['Appointment', () => AppointmentModel], ['Feature', () => FeatureModel], ['PartnerTag', () => PartnerTagModel], ['PartnerPractice', () => PartnerPracticeModel], ['PmsBookingIntent', () => PmsBookingIntentModel], ['UserInputValidationFailed', () => UserInputValidationFailedModel], ['Notification', () => NotificationModel], ['AppointmentUnavailable', () => AppointmentUnavailableModel], ['RootQueryType', () => RootQueryTypeModel], ['AppointmentRequest', () => AppointmentRequestModel], ['ReservationUnavailable', () => ReservationUnavailableModel], ['PracticeAppointmentAnalytics', () => PracticeAppointmentAnalyticsModel], ['FieldError', () => FieldErrorModel], ['DayOfWeekMismatch', () => DayOfWeekMismatchModel], ['AppointmentType', () => AppointmentTypeModel], ['DolphinBookingIntent', () => DolphinBookingIntentModel], ['Practice', () => PracticeModel], ['Meta', () => MetaModel], ['Location', () => LocationModel], ['Patient', () => PatientModel], ['ProviderAppointmentAnalytics', () => ProviderAppointmentAnalyticsModel], ['SearchCoords', () => SearchCoordsModel], ['Partner', () => PartnerModel], ['SearchLocations', () => SearchLocationsModel], ['PartnerTier', () => PartnerTierModel], ['AppointmentLimitReached', () => AppointmentLimitReachedModel], ['User', () => UserModel], ['FindAProvider', () => FindAProviderModel], ['PmsAvailableAppointment', () => PmsAvailableAppointmentModel], ['RootMutationType', () => RootMutationTypeModel]], ['Provider', 'Appointment', 'PartnerTag', 'PartnerPractice', 'PmsBookingIntent', 'Notification', 'AppointmentRequest', 'PracticeAppointmentAnalytics', 'AppointmentType', 'DolphinBookingIntent', 'Practice', 'Location', 'ProviderAppointmentAnalytics', 'Partner', 'User'], "js"))
  .props({
    providers: types.optional(types.map(types.late(() => ProviderModel)), {}),
    appointments: types.optional(types.map(types.late(() => AppointmentModel)), {}),
    partnerTags: types.optional(types.map(types.late(() => PartnerTagModel)), {}),
    partnerPractices: types.optional(types.map(types.late(() => PartnerPracticeModel)), {}),
    pmsBookingIntents: types.optional(types.map(types.late(() => PmsBookingIntentModel)), {}),
    notifications: types.optional(types.map(types.late(() => NotificationModel)), {}),
    appointmentRequests: types.optional(types.map(types.late(() => AppointmentRequestModel)), {}),
    practiceAppointmentAnalytics: types.optional(types.map(types.late(() => PracticeAppointmentAnalyticsModel)), {}),
    appointmentTypes: types.optional(types.map(types.late(() => AppointmentTypeModel)), {}),
    dolphinBookingIntents: types.optional(types.map(types.late(() => DolphinBookingIntentModel)), {}),
    practices: types.optional(types.map(types.late(() => PracticeModel)), {}),
    locations: types.optional(types.map(types.late(() => LocationModel)), {}),
    providerAppointmentAnalytics: types.optional(types.map(types.late(() => ProviderAppointmentAnalyticsModel)), {}),
    partners: types.optional(types.map(types.late(() => PartnerModel)), {}),
    users: types.optional(types.map(types.late(() => UserModel)), {})
  })
  .actions(self => ({
    // List appointment types for a practice
    queryAppointmentTypes(variables, resultSelector = appointmentTypeModelPrimitives.toString(), options = {}) {
      return self.query(`query appointmentTypes { appointmentTypes {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentTypeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List Dolphin reservations
    queryDolphinReservations(variables, resultSelector = dolphinReservationModelPrimitives.toString(), options = {}) {
      return self.query(`query dolphinReservations($endDateUtc: Date!, $locationId: ID!, $startDateUtc: Date!) { dolphinReservations(endDateUtc: $endDateUtc, locationId: $locationId, startDateUtc: $startDateUtc) {
        ${typeof resultSelector === "function" ? resultSelector(new DolphinReservationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Find a provider
    queryFindAProvider(variables, resultSelector = findAProviderModelPrimitives.toString(), options = {}) {
      return self.query(`query findAProvider($address: String!, $apptDate: Date, $apptTime: Time, $partnerId: ID, $radiusInMiles: Int, $specialty: String) { findAProvider(address: $address, apptDate: $apptDate, apptTime: $apptTime, partnerId: $partnerId, radiusInMiles: $radiusInMiles, specialty: $specialty) {
        ${typeof resultSelector === "function" ? resultSelector(new FindAProviderModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a location
    queryLocation(variables, resultSelector = locationModelPrimitives.toString(), options = {}) {
      return self.query(`query location($id: ID!) { location(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new LocationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    queryLocations(variables, resultSelector = locationModelPrimitives.toString(), options = {}) {
      return self.query(`query locations { locations {
        ${typeof resultSelector === "function" ? resultSelector(new LocationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List notifications for authenticated user's practice. Defaults to unread.
    queryNotifications(variables, resultSelector = notificationModelPrimitives.toString(), options = {}) {
      return self.query(`query notifications($status: NotificationStatus) { notifications(status: $status) {
        ${typeof resultSelector === "function" ? resultSelector(new NotificationModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a partner
    queryPartner(variables, resultSelector = partnerModelPrimitives.toString(), options = {}) {
      return self.query(`query partner($id: ID!) { partner(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new PartnerModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List all partners
    queryPartners(variables, resultSelector = partnerModelPrimitives.toString(), options = {}) {
      return self.query(`query partners { partners {
        ${typeof resultSelector === "function" ? resultSelector(new PartnerModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a patient
    queryPatient(variables, resultSelector = patientModelPrimitives.toString(), options = {}) {
      return self.query(`query patient($id: ID!, $source: PatientSource!) { patient(id: $id, source: $source) {
        ${typeof resultSelector === "function" ? resultSelector(new PatientModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List available appointments for PMS integration
    queryPmsAvailableAppointments(variables, resultSelector = pmsAvailableAppointmentModelPrimitives.toString(), options = {}) {
      return self.query(`query pmsAvailableAppointments($endDateUtc: Date!, $locationId: ID!, $startDateUtc: Date!) { pmsAvailableAppointments(endDateUtc: $endDateUtc, locationId: $locationId, startDateUtc: $startDateUtc) {
        ${typeof resultSelector === "function" ? resultSelector(new PmsAvailableAppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get a practice
    queryPractice(variables, resultSelector = practiceModelPrimitives.toString(), options = {}) {
      return self.query(`query practice($id: ID!) { practice(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new PracticeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List appointment analytics for all practices
    queryPracticeAppointmentAnalytics(variables, resultSelector = practiceAppointmentAnalyticsModelPrimitives.toString(), options = {}) {
      return self.query(`query practiceAppointmentAnalytics($fromDate: Date!, $toDate: Date!) { practiceAppointmentAnalytics(fromDate: $fromDate, toDate: $toDate) {
        ${typeof resultSelector === "function" ? resultSelector(new PracticeAppointmentAnalyticsModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get all Practices
    queryPractices(variables, resultSelector = practiceModelPrimitives.toString(), options = {}) {
      return self.query(`query practices { practices {
        ${typeof resultSelector === "function" ? resultSelector(new PracticeModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Get provider type
    queryProvider(variables, resultSelector = providerModelPrimitives.toString(), options = {}) {
      return self.query(`query provider($id: ID!) { provider(id: $id) {
        ${typeof resultSelector === "function" ? resultSelector(new ProviderModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List appointment analytics for all providers
    queryProviderAppointmentAnalytics(variables, resultSelector = providerAppointmentAnalyticsModelPrimitives.toString(), options = {}) {
      return self.query(`query providerAppointmentAnalytics($fromDate: Date!, $toDate: Date!) { providerAppointmentAnalytics(fromDate: $fromDate, toDate: $toDate) {
        ${typeof resultSelector === "function" ? resultSelector(new ProviderAppointmentAnalyticsModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Search locations with bounding box of coordinates
    querySearchLocations(variables, resultSelector = searchLocationsModelPrimitives.toString(), options = {}) {
      return self.query(`query searchLocations($apptDate: Date, $apptTime: Time, $boundingBox: BoundingBox!, $partnerId: ID, $specialty: String) { searchLocations(apptDate: $apptDate, apptTime: $apptTime, boundingBox: $boundingBox, partnerId: $partnerId, specialty: $specialty) {
        ${typeof resultSelector === "function" ? resultSelector(new SearchLocationsModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // List specialties
    querySpecialties(variables, resultSelector = specialtyModelPrimitives.toString(), options = {}) {
      return self.query(`query specialties { specialties {
        ${typeof resultSelector === "function" ? resultSelector(new SpecialtyModelSelector()).toString() : resultSelector}
      } }`, variables, options)
    },
    // Book an appointment
    mutateBookAppointment(variables, resultSelector = appointmentModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation bookAppointment($appointmentId: ID!, $locale: Locale, $locationId: ID, $partnerId: ID, $patient: PatientInput!, $providerId: ID, $referrer: String) { bookAppointment(appointmentId: $appointmentId, locale: $locale, locationId: $locationId, partnerId: $partnerId, patient: $patient, providerId: $providerId, referrer: $referrer) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Book a Dolphin appointment
    mutateBookDolphinAppointment(variables, resultSelector = bookDolphinAppointmentResultModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation bookDolphinAppointment($datetime: NaiveDateTime!, $locationId: ID!, $partnerId: ID, $patient: DolphinPatientInput!) { bookDolphinAppointment(datetime: $datetime, locationId: $locationId, partnerId: $partnerId, patient: $patient) {
        ${typeof resultSelector === "function" ? resultSelector(new BookDolphinAppointmentResultModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Book a PMS appointment
    mutateBookPmsAppointment(variables, resultSelector = bookPmsAppointmentResultModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation bookPmsAppointment($availabilitySlotId: ID!, $date: Date!, $partnerId: ID, $patient: PmsPatientInput!) { bookPmsAppointment(availabilitySlotId: $availabilitySlotId, date: $date, partnerId: $partnerId, patient: $patient) {
        ${typeof resultSelector === "function" ? resultSelector(new BookPmsAppointmentResultModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Create an appointment
    mutateCreateAppointment(variables, resultSelector = appointmentModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation createAppointment($appointmentTypeId: ID, $end: DateTime!, $locationId: ID!, $providerId: ID!, $start: DateTime!) { createAppointment(appointmentTypeId: $appointmentTypeId, end: $end, locationId: $locationId, providerId: $providerId, start: $start) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Request an appointment
    mutateRequestAppointment(variables, resultSelector = appointmentRequestModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation requestAppointment($isEmergency: Boolean, $locale: Locale, $locationId: ID!, $partnerId: ID, $patient: PatientInput!, $providerId: ID, $referrer: String, $yapiRequest: Boolean) { requestAppointment(isEmergency: $isEmergency, locale: $locale, locationId: $locationId, partnerId: $partnerId, patient: $patient, providerId: $providerId, referrer: $referrer, yapiRequest: $yapiRequest) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentRequestModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
    // Update an appointment
    mutateUpdateAppointment(variables, resultSelector = appointmentModelPrimitives.toString(), optimisticUpdate) {
      return self.mutate(`mutation updateAppointment($appointmentTypeId: ID, $end: DateTime, $id: ID!, $locationId: ID, $providerId: ID, $start: DateTime) { updateAppointment(appointmentTypeId: $appointmentTypeId, end: $end, id: $id, locationId: $locationId, providerId: $providerId, start: $start) {
        ${typeof resultSelector === "function" ? resultSelector(new AppointmentModelSelector()).toString() : resultSelector}
      } }`, variables, optimisticUpdate)
    },
  }))
