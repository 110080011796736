import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { Button } from '@mui/material'

const styles = {
  container: {
    display: 'flex',
    backgroundColor: 'rgba(255,255,255,0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
    height: '100vh',
  },
  wrapper: {
    cursor: 'pointer',
  },
}

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any, //eslint-disable-line
  }
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)

      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.error) {
      return (
        <div style={styles.container}>
          <div style={styles.wrapper}>
            <p>We&apos;re sorry — something&apos;s gone wrong.</p>
            <p>
              Our team has been notified, feel free to let us know what
              happened.
            </p>
            <Button
              color="primary"
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report
            </Button>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
