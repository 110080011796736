/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"
import { ProviderModel } from "./ProviderModel"
import { ProviderModelSelector } from "./ProviderModel.base"


/**
 * PartnerTierBase
 * auto generated base class for the model PartnerTierModel.
 *
 * A Partner Tier
 */
export const PartnerTierModelBase = ModelBase
  .named('PartnerTier')
  .props({
    __typename: types.optional(types.literal("PartnerTier"), "PartnerTier"),
    description: types.union(types.undefined, types.null, types.string),
    hexColor: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
    provider: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => ProviderModel))),
    rank: types.union(types.undefined, types.null, types.integer),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PartnerTierModelSelector extends QueryBuilder {
  get description() { return this.__attr(`description`) }
  get hexColor() { return this.__attr(`hexColor`) }
  get name() { return this.__attr(`name`) }
  get rank() { return this.__attr(`rank`) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
  provider(builder) { return this.__child(`provider`, ProviderModelSelector, builder) }
}
export function selectFromPartnerTier() {
  return new PartnerTierModelSelector()
}

export const partnerTierModelPrimitives = selectFromPartnerTier().description.hexColor.name.rank
