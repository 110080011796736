import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/system/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { useQuery } from '../models'
import SpecialtyIcon from './SpecialtyIcon'

const MaybeSpecialtyField = observer(() => {
  const { store } = useQuery()
  const specialty = store.searchStore.specialty
  const { t } = useTranslation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const defaultSpecialtyOption = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <SpecialtyIcon />
      {t('Specialties')}
    </Box>
  )

  const renderValue = value => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SpecialtyIcon />
        {value}
      </Box>
    )
  }

  if (!store.hasSpecialties) {
    return null
  }

  if (store.specialtyFieldEnabled) {
    return (
      <FormControl>
        <Select
          id="specialty-filter"
          displayEmpty
          disableUnderline
          data-testid="specialtyField"
          variant="standard"
          value={specialty}
          renderValue={renderValue}
          onChange={event => store.searchStore.setSpecialty(event.target.value)}
          inputProps={{
            'aria-label': 'Specialty Filter',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: {
              sx: {
                borderRadius: '8px',
              },
            },
            sx: {
              marginBlock: '0.5rem',
            },
          }}
          sx={{
            fontSize: '0.9rem',
            fontWeight: 500,
            [`& .MuiSelect-select`]: {
              pt: '6.5px',
              pb: '5px',
              pl: '8px',
              color: 'secondary.main',
              background: '#FFF',
              borderRadius: '8px',
              border: mobile ? 1 : 0,
              boxShadow: mobile ? 0 : 3,
              '&:focus': {
                borderRadius: 2,
                background: '#FFF',
              },
            },
            [`& .MuiSelect-icon`]: {
              color: 'secondary.main',
            },
          }}
        >
          <MenuItem value="Specialties">{defaultSpecialtyOption}</MenuItem>
          {store.sortedSpecialties.map(specialty => (
            <MenuItem key={specialty.name} value={specialty.name}>
              {t(specialty.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
})

MaybeSpecialtyField.displayName = 'MaybeSpecialtyField'
export default MaybeSpecialtyField
