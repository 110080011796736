/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PracticeModel } from "./PracticeModel"
import { PracticeModelSelector } from "./PracticeModel.base"


/**
 * UserBase
 * auto generated base class for the model UserModel.
 *
 * A User object that describes a user entity
 */
export const UserModelBase = ModelBase
  .named('User')
  .props({
    __typename: types.optional(types.literal("User"), "User"),
    email: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    practice: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PracticeModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class UserModelSelector extends QueryBuilder {
  get email() { return this.__attr(`email`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  practice(builder) { return this.__child(`practice`, PracticeModelSelector, builder) }
}
export function selectFromUser() {
  return new UserModelSelector()
}

export const userModelPrimitives = selectFromUser().email.name
