import { UserModelBase } from "./UserModel.base"


/* A graphql query fragment builders for UserModel */
export { selectFromUser, userModelPrimitives, UserModelSelector } from "./UserModel.base"

/**
 * UserModel
 *
 * A User object that describes a user entity
 */
export const UserModel = UserModelBase
