import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PartnerTagIcon = () => {
  return (
    <SvgIcon
      color="secondary"
      sx={{
        userSelect: 'none',
        pointerEvents: 'none',
        mr: 1,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
        fill="currentColor"
      >
        <path d="M251.848-123.694q-13.665 0-22.91-9.244-9.244-9.245-9.244-22.91v-267.998q0-13.666 9.244-22.91 9.245-9.244 22.91-9.244h44.153v-134.152q0-2.308-.769-4.424-.769-2.115-2.692-4.039l-81.692-82.076q-18.693-18.693-18.885-45.423-.192-26.731 18.885-45.423l90.846-90.846q7.589-7.923 18.025-7.73 10.435.192 18.358 8.034Q346-854.237 346-843.913q0 10.324-7.923 17.914l-90.461 90.846q-3.462 3.846-3.27 9.039.193 5.193 3.654 8.654l80.923 81.308q9.846 9.846 14.462 21.51Q348-602.978 348-590.537v134.692h44.154q13.665 0 22.909 9.2 9.244 9.199 9.244 22.799v267.998q0 13.665-9.244 22.91-9.244 9.244-22.909 9.244H251.848Zm315.998 0q-13.665 0-22.909-9.244-9.244-9.245-9.244-22.91v-267.998q0-13.666 9.244-22.91T567.846-456H612v-123.461q-43.154-8.539-71.73-42.7-28.577-34.161-28.577-80.522 0-52.7 36.615-89.315 36.615-36.615 89.691-36.615 53.077 0 89.692 36.85 36.615 36.85 36.615 89.235 0 46.09-28.577 80.309-28.577 34.219-71.73 42.758V-456h44.221q13.694 0 22.89 9.244t9.196 22.91v267.998q0 13.665-9.244 22.91-9.245 9.244-22.91 9.244H567.846Zm70.509-504.305q30.798 0 52.375-21.932t21.577-52.731q0-30.798-21.852-52.375-21.853-21.577-52.539-21.577t-52.455 21.852q-21.77 21.852-21.77 52.539 0 30.686 21.933 52.455 21.932 21.769 52.731 21.769ZM271.693-175.693h100.616v-228.308H271.693v228.308Zm315.998 0h100.616v-228.308H587.691v228.308Zm-315.998 0h100.616-100.616Zm315.998 0h100.616-100.616Z" />
      </svg>
    </SvgIcon>
  )
}

export default PartnerTagIcon
