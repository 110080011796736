/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * AppointmentUnavailableBase
 * auto generated base class for the model AppointmentUnavailableModel.
 */
export const AppointmentUnavailableModelBase = ModelBase
  .named('AppointmentUnavailable')
  .props({
    __typename: types.optional(types.literal("AppointmentUnavailable"), "AppointmentUnavailable"),
    message: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class AppointmentUnavailableModelSelector extends QueryBuilder {
  get message() { return this.__attr(`message`) }
}
export function selectFromAppointmentUnavailable() {
  return new AppointmentUnavailableModelSelector()
}

export const appointmentUnavailableModelPrimitives = selectFromAppointmentUnavailable().message
