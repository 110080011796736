/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PartnerModel } from "./PartnerModel"
import { PartnerModelSelector } from "./PartnerModel.base"


/**
 * PartnerTagBase
 * auto generated base class for the model PartnerTagModel.
 *
 * A Partner Tag
 */
export const PartnerTagModelBase = ModelBase
  .named('PartnerTag')
  .props({
    __typename: types.optional(types.literal("PartnerTag"), "PartnerTag"),
    description: types.union(types.undefined, types.null, types.string),
    hexColor: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    name: types.union(types.undefined, types.null, types.string),
    partner: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PartnerModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PartnerTagModelSelector extends QueryBuilder {
  get description() { return this.__attr(`description`) }
  get hexColor() { return this.__attr(`hexColor`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  partner(builder) { return this.__child(`partner`, PartnerModelSelector, builder) }
}
export function selectFromPartnerTag() {
  return new PartnerTagModelSelector()
}

export const partnerTagModelPrimitives = selectFromPartnerTag().description.hexColor.name
