/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"


/**
 * SpecialtyBase
 * auto generated base class for the model SpecialtyModel.
 *
 * A specialty
 */
export const SpecialtyModelBase = ModelBase
  .named('Specialty')
  .props({
    __typename: types.optional(types.literal("Specialty"), "Specialty"),
    name: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class SpecialtyModelSelector extends QueryBuilder {
  get name() { return this.__attr(`name`) }
}
export function selectFromSpecialty() {
  return new SpecialtyModelSelector()
}

export const specialtyModelPrimitives = selectFromSpecialty().name
