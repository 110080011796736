/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FindAProviderConfigModel } from "./FindAProviderConfigModel"
import { FindAProviderConfigModelSelector } from "./FindAProviderConfigModel.base"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { PartnerTagModel } from "./PartnerTagModel"
import { PartnerTagModelSelector } from "./PartnerTagModel.base"


/**
 * PartnerBase
 * auto generated base class for the model PartnerModel.
 *
 * A Partner object that describes a partner entity
 */
export const PartnerModelBase = ModelBase
  .named('Partner')
  .props({
    __typename: types.optional(types.literal("Partner"), "Partner"),
    findAProviderConfig: types.union(types.undefined, types.null, types.late(() => FindAProviderConfigModel)),
    gaTrackingCode: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    name: types.union(types.undefined, types.null, types.string),
    partnerTags: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => PartnerTagModel))))),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class PartnerModelSelector extends QueryBuilder {
  get gaTrackingCode() { return this.__attr(`gaTrackingCode`) }
  get id() { return this.__attr(`id`) }
  get name() { return this.__attr(`name`) }
  findAProviderConfig(builder) { return this.__child(`findAProviderConfig`, FindAProviderConfigModelSelector, builder) }
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  partnerTags(builder) { return this.__child(`partnerTags`, PartnerTagModelSelector, builder) }
}
export function selectFromPartner() {
  return new PartnerModelSelector()
}

export const partnerModelPrimitives = selectFromPartner().gaTrackingCode.name
