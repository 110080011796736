/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { QueryBuilder } from "mst-gql"
import { AppointmentLimitReachedModelType } from "./AppointmentLimitReachedModel"
import { AppointmentLimitReachedModelSelector, appointmentLimitReachedModelPrimitives } from "./AppointmentLimitReachedModel.base"
import { AppointmentUnavailableModelType } from "./AppointmentUnavailableModel"
import { AppointmentUnavailableModelSelector, appointmentUnavailableModelPrimitives } from "./AppointmentUnavailableModel.base"
import { DayOfWeekMismatchModelType } from "./DayOfWeekMismatchModel"
import { DayOfWeekMismatchModelSelector, dayOfWeekMismatchModelPrimitives } from "./DayOfWeekMismatchModel.base"
import { PmsBookingIntentModelType } from "./PmsBookingIntentModel"
import { PmsBookingIntentModelSelector, pmsBookingIntentModelPrimitives } from "./PmsBookingIntentModel.base"
import { UserInputValidationFailedModelType } from "./UserInputValidationFailedModel"
import { UserInputValidationFailedModelSelector, userInputValidationFailedModelPrimitives } from "./UserInputValidationFailedModel.base"

export class BookPmsAppointmentResultModelSelector extends QueryBuilder {
  pmsBookingIntent(builder) { return this.__inlineFragment(`PmsBookingIntent`, PmsBookingIntentModelSelector, builder) }
  userInputValidationFailed(builder) { return this.__inlineFragment(`UserInputValidationFailed`, UserInputValidationFailedModelSelector, builder) }
  appointmentUnavailable(builder) { return this.__inlineFragment(`AppointmentUnavailable`, AppointmentUnavailableModelSelector, builder) }
  dayOfWeekMismatch(builder) { return this.__inlineFragment(`DayOfWeekMismatch`, DayOfWeekMismatchModelSelector, builder) }
  appointmentLimitReached(builder) { return this.__inlineFragment(`AppointmentLimitReached`, AppointmentLimitReachedModelSelector, builder) }
}
export function selectFromBookPmsAppointmentResult() {
  return new BookPmsAppointmentResultModelSelector()
}

// provides all primitive fields of union member types combined together
export const bookPmsAppointmentResultModelPrimitives = selectFromBookPmsAppointmentResult().pmsBookingIntent(pmsBookingIntentModelPrimitives).userInputValidationFailed(userInputValidationFailedModelPrimitives).appointmentUnavailable(appointmentUnavailableModelPrimitives).dayOfWeekMismatch(dayOfWeekMismatchModelPrimitives).appointmentLimitReached(appointmentLimitReachedModelPrimitives)