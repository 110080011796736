import { NotificationModelBase } from "./NotificationModel.base"


/* A graphql query fragment builders for NotificationModel */
export { selectFromNotification, notificationModelPrimitives, NotificationModelSelector } from "./NotificationModel.base"

/**
 * NotificationModel
 *
 * A Notification
 */
export const NotificationModel = NotificationModelBase
