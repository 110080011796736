import { datadogRum } from '@datadog/browser-rum'

import {
  APP_VERSION,
  SENTRY_ENVIRONMENT,
  RUM_APP_ID,
  RUM_CLIENT_TOKEN,
} from './constants'
import { isDev } from './utils/helpers'

if (!isDev) {
  datadogRum.init({
    applicationId: RUM_APP_ID,
    clientToken: RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'finerconnect-map',
    env: SENTRY_ENVIRONMENT,
    version: APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}