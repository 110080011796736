import { useQuery } from '../models/reactUtils'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const MobileShowListButton = observer(({ isDesktop, toggleDrawer }) => {
  const { store } = useQuery()
  const { t } = useTranslation()
  const searchStarted = store.searchStore.searchStarted
  const hasLocations = store.locationStore.hasLocations
  const searchBarExpanded = store.searchStore.searchBarExpanded
  const staticPage = store.staticPage

  if (isDesktop) {
    return null
  }
  return (
    <Box
      sx={{
        top: staticPage ? 20 : searchBarExpanded ? 200 : 155,
        zIndex: 100,
        width: '100%',
        position: 'absolute',
        justifyContent: 'center',
        ...(isDesktop && { display: 'none' }),
        ...(hasLocations || searchStarted
          ? { display: 'flex' }
          : { display: 'none' }),
      }}
    >
      <Button
        aria-label="Show List Button"
        color="secondary"
        variant="outlined"
        onClick={() => toggleDrawer(true)}
        sx={{ border: 1, borderRadius: 5, bgcolor: '#FFF' }}
      >
        {t('Show List')}
      </Button>
    </Box>
  )
})

export default MobileShowListButton
