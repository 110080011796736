import React from 'react'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

const MapLoader = ({ message }) => {
  return (
    <Backdrop
      open
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" color="inherit">
          {message}
        </Typography>
      </Grid>
    </Backdrop>
  )
}

MapLoader.propTypes = {
  message: PropTypes.string,
}

export default MapLoader
