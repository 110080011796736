/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { LocationModel } from "./LocationModel"
import { LocationModelSelector } from "./LocationModel.base"
import { PartnerTierModel } from "./PartnerTierModel"
import { PartnerTierModelSelector } from "./PartnerTierModel.base"
import { PracticeModel } from "./PracticeModel"
import { PracticeModelSelector } from "./PracticeModel.base"
import { SpecialtyModel } from "./SpecialtyModel"
import { SpecialtyModelSelector } from "./SpecialtyModel.base"


/**
 * ProviderBase
 * auto generated base class for the model ProviderModel.
 *
 * A Provider
 */
export const ProviderModelBase = ModelBase
  .named('Provider')
  .props({
    __typename: types.optional(types.literal("Provider"), "Provider"),
    bio: types.union(types.undefined, types.null, types.string),
    chewsiIsActive: types.union(types.undefined, types.null, types.boolean),
    id: types.identifier,
    languagesSpoken: types.union(types.undefined, types.null, types.array(types.union(types.null, types.string))),
    locations: types.union(types.undefined, types.null, types.array(types.union(types.null, MSTGQLRef(types.late(() => LocationModel))))),
    name: types.union(types.undefined, types.null, types.string),
    newCustomerFormLink: types.union(types.undefined, types.null, types.string),
    /** Get provider's tier for the given partner id */
    partnerTier: types.union(types.undefined, types.null, types.late(() => PartnerTierModel)),
    photoS3Key: types.union(types.undefined, types.null, types.string),
    practice: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PracticeModel))),
    specialties: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => SpecialtyModel)))),
    websiteLink: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class ProviderModelSelector extends QueryBuilder {
  get bio() { return this.__attr(`bio`) }
  get chewsiIsActive() { return this.__attr(`chewsiIsActive`) }
  get id() { return this.__attr(`id`) }
  get languagesSpoken() { return this.__attr(`languagesSpoken`) }
  get name() { return this.__attr(`name`) }
  get newCustomerFormLink() { return this.__attr(`newCustomerFormLink`) }
  get photoS3Key() { return this.__attr(`photoS3Key`) }
  get websiteLink() { return this.__attr(`websiteLink`) }
  locations(builder) { return this.__child(`locations`, LocationModelSelector, builder) }
  partnerTier(builder, args) { return this.__child(`partnerTier` + (args ? '(' + ['partnerId'].map((argName) => ((args)[argName] ? `${argName}: ${JSON.stringify((args)[argName])}` : null)).filter((v) => v != null).join(', ') + ')' : ''), PartnerTierModelSelector, builder) }
  practice(builder) { return this.__child(`practice`, PracticeModelSelector, builder) }
  specialties(builder) { return this.__child(`specialties`, SpecialtyModelSelector, builder) }
}
export function selectFromProvider() {
  return new ProviderModelSelector()
}

export const providerModelPrimitives = selectFromProvider().bio.chewsiIsActive.languagesSpoken.name.newCustomerFormLink.photoS3Key.websiteLink
