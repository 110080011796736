import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useState } from 'react'

import debounceFn from 'lodash.debounce'

export default function usePlacesAutocompleteService({
  options = {},
  debounce = 300,
}) {
  const [placePredictions, setPlacePredictions] = useState([])
  const [isPlacePredsLoading, setIsPlacePredsLoading] = useState(false)
  const [placeInputValue, setPlaceInputValue] = useState(null)
  const [placesAutocompleteService, setPlacesAutocompleteService] =
    useState(null)
  const placesLibrary = useMapsLibrary('places')
  const [placesService, setPlacesService] = useState(null)

  useEffect(() => {
    if (!placesLibrary) return

    setPlacesService(
      new placesLibrary.PlacesService(document.createElement('div'))
    )
    setPlacesAutocompleteService(new placesLibrary.AutocompleteService())
  }, [placesLibrary])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPlacePredictions = useCallback(
    debounceFn(optionsArg => {
      if (placesAutocompleteService && optionsArg.input)
        placesAutocompleteService.getPlacePredictions(
          {
            ...options,
            ...optionsArg,
          },
          r => {
            setIsPlacePredsLoading(false)
            setPlacePredictions(r || [])
          }
        )
    }, debounce),
    [debounce, debounceFn, placesAutocompleteService, options]
  )

  return {
    placesService: placesService,
    placesAutocompleteService: placesAutocompleteService,
    placePredictions: placeInputValue ? placePredictions : [],
    isPlacePredictionsLoading: isPlacePredsLoading,
    getPlacePredictions: opt => {
      if (opt.input) {
        setPlaceInputValue(opt.input)
        setIsPlacePredsLoading(true)
        debouncedPlacePredictions(opt)
        return
      }
      setPlacePredictions([])
      setPlaceInputValue(null)
      debouncedPlacePredictions(opt)
      setIsPlacePredsLoading(false)
    },
  }
}
