import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import Place from '@mui/icons-material/Place'
import SearchIcon from '@mui/icons-material/Search'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useQuery } from '../models'
import * as Analytics from '../utils/analytics'
import usePlacesService from '../utils/usePlacesAutocompleteService'
import MaybeProviderNameField from './MaybeProviderNameField'

const GooglePlacesAutocomplete = observer(() => {
  // State
  const [showPredictionsList, setShowPredictionsList] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [touched, setTouched] = React.useState(false)

  // Hooks and Context
  const { t } = useTranslation()
  const { store } = useQuery()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const setAutocompleteInputValue = store.searchStore.setAutocompleteInputValue
  const searchBarExpanded = store.searchStore.searchBarExpanded
  const textFieldRef = React.useRef(null)
  const validSearchForm = store.searchStore.validSearchForm
  const loading = store.loading
  const placeholderHint = t('Find a doctor by location...')
  /** Initialize places service for fetching google places */
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    options: {
      types: ['geocode'],
    },
    debounce: 500,
  })

  /** Get place predictions based on user input */
  const fetchPlacePredictions = event => {
    const value = event.target.value
    setAutocompleteInputValue(value)
    if (value) {
      getPlacePredictions({ input: value })
    } else {
      setSelectedIndex(0)
    }
  }

  /** Handle place details response when a place is selected.
   * We need to get place's bounding box and store it in searchStore.
   */
  const handlePlaceDetails = React.useCallback(
    place => {
      store.searchStore.setSearchCoordinates(place.geometry.location.toJSON())
      store.searchStore.searchLocations()
    },
    [store.searchStore]
  )

  /**
   * Handle place selection
   * replace current user input with selected place description
   * fetch details like geometry viewport for selected place
   */
  const selectPlace = React.useCallback(
    place => {
      const { place_id, description } = place
      setAutocompleteInputValue(description)
      Analytics.search(description, store.partnerId)

      placesService.getDetails(
        { placeId: place_id, fields: ['geometry.location'] },
        handlePlaceDetails
      )
      setShowPredictionsList(false)
    },
    [
      setAutocompleteInputValue,
      placesService,
      handlePlaceDetails,
      store.partnerId,
    ]
  )

  /**
   * While the place predictions list is showing,
   * listen to keyboard events to navigate the list
   * Enter key will select the currently highlighted place
   */
  const handleKeyDown = React.useCallback(
    event => {
      if (event.key === 'ArrowUp' && placePredictions.length > 0) {
        setSelectedIndex(prevIndex => {
          if (prevIndex === undefined || prevIndex === 0) {
            return placePredictions.length - 1
          }
          if (prevIndex > 0) {
            return prevIndex - 1
          }
        })
      } else if (event.key === 'ArrowDown') {
        setSelectedIndex(prevIndex => {
          if (prevIndex == null) {
            return 0
          }
          if (prevIndex < placePredictions.length - 1) {
            return prevIndex + 1
          }
          return 0
        })
      } else if (event.key === 'Enter') {
        if (showPredictionsList && placePredictions.length > 0) {
          const place = placePredictions[selectedIndex]
          if (place) {
            selectPlace(place)
            event.stopPropagation()
          }
        }
      }
    },
    [
      placePredictions,
      setSelectedIndex,
      selectPlace,
      selectedIndex,
      showPredictionsList,
    ]
  )

  const handleBlur = () => {
    setTouched(true)
  }

  const textFieldWidth = () => {
    if (textFieldRef.current) {
      return textFieldRef.current.offsetWidth
    }
    return 'auto'
  }

  React.useEffect(() => {
    if (isPlacePredictionsLoading || placePredictions.length > 0) {
      setShowPredictionsList(true)
    }
  }, [placePredictions, isPlacePredictionsLoading])

  React.useEffect(() => {
    if (showPredictionsList) {
      window.addEventListener('keydown', handleKeyDown)
    } else {
      window.removeEventListener('keydown', handleKeyDown)
    }
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [showPredictionsList, handleKeyDown])

  if (store.staticPage) {
    return null
  }
  return (
    <Grid
      sx={{
        border: mobile && !showPredictionsList ? 2 : 0,
        borderRadius: mobile ? '24px' : '24px 24px 0 0',
        borderColor: mobile ? 'primary.main' : 'none',
      }}
    >
      <Grid item>
        <TextField
          sx={{
            borderRadius: showPredictionsList ? '24px 24px 0 0' : '24px',
            bgcolor: '#FFF',
            width: mobile ? 'fill-available' : 358,
            height: 48,
            '& fieldset': { border: 'none' },
          }}
          ref={textFieldRef}
          id="boundingBox"
          name="boundingBox"
          autoComplete="off"
          variant="outlined"
          aria-label="Search Input"
          value={store.searchStore.autocompleteInputValue}
          onChange={fetchPlacePredictions}
          onBlur={handleBlur}
          error={touched && !validSearchForm}
          placeholder={placeholderHint}
          InputProps={{
            sx: {
              height: 48,
            },
            startAdornment: (
              <InputAdornment position="start">
                {store.searchStore.providerNameFilterEnabled && (
                  <IconButton
                    aria-label="Show Provider Filter"
                    size="small"
                    color="secondary"
                    onClick={() => store.searchStore.toggleSearchBarExpansion()}
                  >
                    <FilterListRoundedIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Search Button"
                  disabled={!validSearchForm || loading}
                  size="small"
                  color="primary"
                  onClick={() => store.searchStore.searchLocations()}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {showPredictionsList && (
          <Paper
            sx={{
              position: 'absolute',
              zIndex: 200,
              width: textFieldWidth,
              borderRadius: '0 0 24px 24px',
            }}
          >
            <List>
              {placePredictions.map((suggestion, index) => (
                <ListItemButton
                  data-testid={`place-prediction-${index}`}
                  selected={selectedIndex === index}
                  key={index}
                  onClick={() => selectPlace(suggestion)}
                >
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <Place color="primary" />
                  </ListItemIcon>
                  <ListItemText>
                    <strong>
                      {suggestion.structured_formatting.main_text}
                    </strong>
                    {suggestion.structured_formatting.secondary_text ? (
                      <Typography component="span" variant="body2">
                        , {suggestion.structured_formatting.secondary_text}
                      </Typography>
                    ) : null}
                  </ListItemText>
                </ListItemButton>
              ))}
              {isPlacePredictionsLoading && (
                <ListItem>
                  <ListItemText primary="Loading..." />
                </ListItem>
              )}
            </List>
          </Paper>
        )}
      </Grid>
      <Grid
        aria-label="Provider Field"
        item
        sx={{ marginLeft: '40px', maxWidth: '256px' }}
      >
        {searchBarExpanded && <MaybeProviderNameField />}
      </Grid>
    </Grid>
  )
})

GooglePlacesAutocomplete.displayName = 'GooglePlacesAutocomplete'

export default GooglePlacesAutocomplete
