import { MetaModelBase } from "./MetaModel.base"


/* A graphql query fragment builders for MetaModel */
export { selectFromMeta, metaModelPrimitives, MetaModelSelector } from "./MetaModel.base"

/**
 * MetaModel
 */
export const MetaModel = MetaModelBase
