import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import { useQuery } from '../models/reactUtils'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const MaybeProviderNameField = observer(() => {
  const { store } = useQuery()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const placeholderHint = t('Find a doctor by name...')

  if (store.searchStore.providerNameFilterEnabled) {
    return (
      <TextField
        sx={{
          pl: 1,
          borderRadius: '24px',
          bgcolor: '#FFF',
          width: mobile ? 'fill-available' : 368,
          maxWidth: '256px',
          height: 48,
          '& fieldset': { border: 'none' },
        }}
        id="providerName"
        name="providerName"
        autoComplete="off"
        variant="outlined"
        aria-label="Search for a doctor by name"
        value={store.searchStore.providerName}
        onChange={event => {
          store.searchStore.setProviderName(event.currentTarget.value)
        }}
        placeholder={placeholderHint}
        InputProps={{
          sx: {
            height: 48,
          },
          endAdornment: (
            <InputAdornment position="end">
              {store.searchStore.providerName && (
                <IconButton
                  aria-label="clear field"
                  size="small"
                  title="Clear field"
                  onClick={store.searchStore.resetProviderName}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    )
  }
})

MaybeProviderNameField.displayName = 'MaybeProviderNameField'

export default MaybeProviderNameField
