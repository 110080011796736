/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { createStoreContext, createUseQueryHook } from "mst-gql"
import * as React from "react"


export const StoreContext = createStoreContext(React)

export const useQuery = createUseQueryHook(StoreContext, React)
