import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/system/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import LanguageIcon from './LanguageIcon'
import { useQuery } from '../models/reactUtils'

const MaybeLanguageField = observer(() => {
  const { store } = useQuery()
  const { t } = useTranslation()
  const languages = ['English', 'Spanish', 'French', 'Other']
  const filteredLanguages = store.searchStore.filteredLanguage
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  const defaultLanguageOption = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LanguageIcon />
      {t('Languages')}
    </Box>
  )

  const renderValue = value => {
    const totalLanguages = languages.length
    if (value.length === totalLanguages || value.length === 0) {
      return defaultLanguageOption
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LanguageIcon />
        {value.length === 1 ? value[0] : `${value.length} ${t('Languages')}`}
      </Box>
    )
  }

  if (store.searchStore.languageFilterEnabled) {
    return (
      <FormControl>
        <Select
          id="language-filter"
          multiple
          displayEmpty
          disableUnderline
          value={filteredLanguages}
          renderValue={renderValue}
          onChange={event =>
            store.searchStore.setFilteredLanguage(event.target.value)
          }
          variant="standard"
          data-testid="languageFilter"
          inputProps={{ 'aria-label': 'Language Filter' }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: {
              sx: {
                borderRadius: '8px',
              },
            },
            sx: {
              marginBlock: '0.5rem',
            },
          }}
          sx={{
            fontSize: '0.9rem',
            fontWeight: 500,
            [`& .MuiSelect-select`]: {
              pt: '6.5px',
              pb: '5px',
              pl: '8px',
              color: 'secondary.main',
              background: '#FFF',
              borderRadius: '8px',
              border: mobile ? 1 : 0,
              boxShadow: mobile ? 0 : 3,
              '&:focus': {
                borderRadius: 2,
                background: '#FFF',
              },
            },
            [`& .MuiSelect-icon`]: {
              color: 'secondary.main',
            },
          }}
        >
          <MenuItem value="Languages" disabled>
            {defaultLanguageOption}
          </MenuItem>
          {languages.map(language => (
            <MenuItem key={language} value={language}>
              <Checkbox checked={filteredLanguages.indexOf(language) > -1} />
              {language}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
  return null
})

MaybeLanguageField.displayName = 'MaybeLanguageField'
export default MaybeLanguageField
