import React from 'react'

import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useQuery } from '../models'
import DistanceField from './DistanceField'
import MaybeLanguageField from './MaybeLanguageField'
import MaybePartnerTagsField from './MaybePartnerTagsField'
import MaybeSpecialtyField from './MaybeSpecialtyField'
import ShowAllLocationsButton from './ShowAllLocationsButton'

const FilterChipBar = () => {
  const { store } = useQuery()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  if (store.staticPage) {
    return null
  }
  return (
    <Grid
      container
      aria-label="Filter Bar"
      spacing={1}
      sx={{
        flexWrap: 'nowrap',
        width: 'fit-content',
        pb: mobile ? 1 : 0,
        px: mobile ? 2 : 0,
      }}
    >
      <Grid item>
        <ShowAllLocationsButton />
      </Grid>
      <Grid item>
        <DistanceField />
      </Grid>
      <Grid item>
        <MaybeSpecialtyField />
      </Grid>
      <Grid item>
        <MaybeLanguageField />
      </Grid>
      <Grid item>
        <MaybePartnerTagsField />
      </Grid>
    </Grid>
  )
}

export default FilterChipBar
