/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FieldErrorModel } from "./FieldErrorModel"
import { FieldErrorModelSelector } from "./FieldErrorModel.base"


/**
 * UserInputValidationFailedBase
 * auto generated base class for the model UserInputValidationFailedModel.
 */
export const UserInputValidationFailedModelBase = ModelBase
  .named('UserInputValidationFailed')
  .props({
    __typename: types.optional(types.literal("UserInputValidationFailed"), "UserInputValidationFailed"),
    fieldErrors: types.union(types.undefined, types.null, types.array(types.union(types.null, types.late(() => FieldErrorModel)))),
    message: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class UserInputValidationFailedModelSelector extends QueryBuilder {
  get message() { return this.__attr(`message`) }
  fieldErrors(builder) { return this.__child(`fieldErrors`, FieldErrorModelSelector, builder) }
}
export function selectFromUserInputValidationFailed() {
  return new UserInputValidationFailedModelSelector()
}

export const userInputValidationFailedModelPrimitives = selectFromUserInputValidationFailed().message
