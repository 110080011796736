import { APIProvider } from '@vis.gl/react-google-maps'
import React from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { camelizeKeys } from 'humps'
import { reaction } from 'mobx'
import { createHttpClient } from 'mst-gql'
import qs from 'qs'

import { colors } from '../assets/colors'
import { API_URL, GOOGLE_MAP_KEY, GOOGLE_PLACES_LIBRARY } from '../constants'
import { RootStore, StoreContext } from '../models'
import * as Analytics from '../utils/analytics'
import { isDev } from '../utils/helpers'
import { logger } from '../utils/logger'
import { createRouter } from '../utils/router'
import Alert from './Alert'
import ErrorBoundary from './ErrorBoundary'
import Modal from './LazyModal'
import MainContent from './MainContent'

const dependencies = {
  logger,
  gqlHttpClient: createHttpClient(API_URL),
  navigator: navigator,
}
const rootStore = RootStore.create({}, dependencies)
const params = camelizeKeys(
  qs.parse(window.location.search, { ignoreQueryPrefix: true })
)

const brandingPrimary = params.brandingColorPrimary
  ? `#${params.brandingColorPrimary}`
  : null
const brandingSecondary = params.brandingColorSecondary
  ? `#${params.brandingColorSecondary}`
  : null

const theme = createTheme({
  palette: {
    primary: {
      main: brandingPrimary || colors.primary,
    },
    secondary: {
      main: brandingSecondary || colors.secondary,
    },
  },
  typography: {
    useNextVariants: true,
  },
})

if (isDev) {
  window.store = rootStore
}

const App = () => {
  return (
    <ErrorBoundary>
      <StoreContext.Provider value={rootStore}>
        <APIProvider
          apiKey={GOOGLE_MAP_KEY}
          libraries={GOOGLE_PLACES_LIBRARY}
          loading={'async'}
        >
          <ThemeProvider theme={theme}>
            <MainContent />
            <React.Suspense fallback={<div></div>}>
              <Modal />
              <Alert />
            </React.Suspense>
          </ThemeProvider>
        </APIProvider>
      </StoreContext.Provider>
    </ErrorBoundary>
  )
}

export default App

/**
 * Routing
 */
reaction(
  () => rootStore.currentUrl,
  path => {
    Analytics.pushAnalytics(path, rootStore.partnerId)
    if (window.location.pathname !== path)
      window.history.pushState(null, null, `${path}${window.location.search}`)
  }
)

const router = createRouter({
  '/providers/:providerId/locations/:locationId': ({
    providerId,
    locationId,
  }) => rootStore.openProviderLocation(providerId, locationId),
  '/practice/:practiceId/locations/:locationId': ({ practiceId, locationId }) =>
    rootStore.openPracticeLocation(practiceId, locationId),
  '/practice/:practiceId': ({ practiceId }) =>
    rootStore.openPractice(practiceId),
  '/': rootStore.openRoot,
})

window.onpopstate = function historyChange(ev) {
  if (ev.type === 'popstate') router(window.location.pathname)
}

router(window.location.pathname)
Analytics.pushAnalytics(window.location.pathname, rootStore.partnerId)
