/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AppointmentModel } from "./AppointmentModel"
import { AppointmentModelSelector } from "./AppointmentModel.base"
import { AppointmentRequestModel } from "./AppointmentRequestModel"
import { AppointmentRequestModelSelector } from "./AppointmentRequestModel.base"
import { PracticeModel } from "./PracticeModel"
import { PracticeModelSelector } from "./PracticeModel.base"


/**
 * NotificationBase
 * auto generated base class for the model NotificationModel.
 *
 * A Notification
 */
export const NotificationModelBase = ModelBase
  .named('Notification')
  .props({
    __typename: types.optional(types.literal("Notification"), "Notification"),
    appointment: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentModel))),
    appointmentRequest: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => AppointmentRequestModel))),
    id: types.identifier,
    insertedAt: types.union(types.undefined, types.null, types.string),
    notificationType: types.union(types.undefined, types.null, types.string),
    practice: types.union(types.undefined, types.null, MSTGQLRef(types.late(() => PracticeModel))),
    readAt: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore()
    }
  }))

export class NotificationModelSelector extends QueryBuilder {
  get id() { return this.__attr(`id`) }
  get insertedAt() { return this.__attr(`insertedAt`) }
  get notificationType() { return this.__attr(`notificationType`) }
  get readAt() { return this.__attr(`readAt`) }
  appointment(builder) { return this.__child(`appointment`, AppointmentModelSelector, builder) }
  appointmentRequest(builder) { return this.__child(`appointmentRequest`, AppointmentRequestModelSelector, builder) }
  practice(builder) { return this.__child(`practice`, PracticeModelSelector, builder) }
}
export function selectFromNotification() {
  return new NotificationModelSelector()
}

export const notificationModelPrimitives = selectFromNotification().insertedAt.notificationType.readAt
