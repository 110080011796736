import { PartnerPracticeModelBase } from "./PartnerPracticeModel.base"


/* A graphql query fragment builders for PartnerPracticeModel */
export { selectFromPartnerPractice, partnerPracticeModelPrimitives, PartnerPracticeModelSelector } from "./PartnerPracticeModel.base"

/**
 * PartnerPracticeModel
 *
 * A Partner Practice relation object that describes a many to many relationship between a partner and a practice entity
 */
export const PartnerPracticeModel = PartnerPracticeModelBase
