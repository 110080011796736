import { PracticeModelBase } from "./PracticeModel.base"


/* A graphql query fragment builders for PracticeModel */
export { selectFromPractice, practiceModelPrimitives, PracticeModelSelector } from "./PracticeModel.base"

/**
 * PracticeModel
 *
 * A Practice object that describes a practice entity
 */
export const PracticeModel = PracticeModelBase
