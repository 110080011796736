import React from 'react'
import { useTranslation } from 'react-i18next'

import LanguageIcon from '@mui/icons-material/Language'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const LanguageSelect = () => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { i18n } = useTranslation()
  const detectedLanguage = i18n.resolvedLanguage || 'en-US'
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = e => {
    setAnchorEl(e.currentTarget)
  }
  const changeLanguage = langOpt => {
    i18n.changeLanguage(langOpt)
    setAnchorEl(null)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const langOptions = [
    {
      name: 'English',
      value: 'en',
    },
    {
      name: 'French',
      value: 'fr',
    },
    {
      name: 'Spanish',
      value: 'es',
    },
  ]

  return (
    <>
      <Button
        variant="contained"
        sx={{
          // Color, BorderRadius, BoxShadow, and Hover are matched from Google Map's CSS
          position: 'absolute',
          right: 10,
          bottom: mobile ? 194 : 116,
          padding: 1,
          minWidth: 'inherit',
          color: '#666',
          backgroundColor: '#fff',
          borderRadius: '2px',
          boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
          ':hover': {
            color: '#333',
            backgroundColor: '#fff',
            boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
          },
        }}
        aria-label="Select Language"
        aria-controls="langSelector"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <LanguageIcon />
      </Button>
      <Menu
        id="langSelector"
        className=""
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={true}>Select Language:</MenuItem>
        {langOptions.map(({ name, value }) => (
          <MenuItem
            key={name}
            onClick={() => changeLanguage(value)}
            selected={detectedLanguage === value}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSelect
