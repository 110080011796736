import { types, getEnv, onPatch } from 'mobx-state-tree'
import qs from 'qs'
import { camelizeKeys } from 'humps'

import { RootStoreBase } from './RootStore.base'
import { LocationStore } from './LocationStore'
import { ProviderStore } from './ProviderStore'
import { PracticeStore } from './PracticeStore'
import { SearchStore } from './SearchStore'
import { PARTNER } from './queries'
import { SpecialtyModel } from './SpecialtyModel'
import { byName } from '../utils/helpers'

export const RootStore = RootStoreBase.props({
  page: '/',
  loading: false,
  staticPage: false,
  errorMessage: '',
  partnerId: '',
  practiceId: '',
  locationId: '',
  providerId: '',
  locationStore: types.optional(LocationStore, {}),
  providerStore: types.optional(ProviderStore, {}),
  practiceStore: types.optional(PracticeStore, {}),
  searchStore: types.optional(SearchStore, {}),
  specialties: types.optional(types.array(SpecialtyModel), []),
  mapZoom: types.optional(types.number, 5),
  mapCenter: types.optional(
    types.model('MapCenter', {
      lat: types.number,
      lng: types.number,
    }),
    { lat: 39.8283, lng: -98.5795 }
  ),
  boundingBox: types.frozen({
    south: 25.82,
    west: -124.39,
    north: 49.38,
    east: -66.94,
  }),
})
  .views(self => ({
    get currentUrl() {
      switch (self.page) {
        case 'providerLocation':
          return `/providers/${self.providerId}/locations/${self.locationId}`
        case 'practiceLocation':
          return `/practice/${self.practiceId}/locations/${self.locationId}/`
        case 'practice':
          return `/practice/${self.practiceId}/`
        default:
          return '/'
      }
    },
    get specialtyFieldEnabled() {
      if (self.partner) {
        return self.partner.findAProviderConfig.isSpecialtyFilterEnabled
      }
      return true
    },
    get hasSpecialties() {
      return self.specialties.length > 0
    },
    get sortedSpecialties() {
      return self.specialties.slice().sort(byName)
    },
    get loadAllLocationsButtonEnabled() {
      if (self.partner) {
        return self.partner.findAProviderConfig.isLoadAllLocationsButtonEnabled
      }
      return false
    },
    get partner() {
      if (self.partnerId) {
        return self.partners.get(self.partnerId)
      }
      return null
    },
    get modalOpen() {
      return self.locationId !== '' || self.providerId !== ''
    },
    get canShowAllLocations() {
      return (
        self.partner &&
        self.partner.findAProviderConfig.isLoadAllLocationsEnabled &&
        self.searchStore.searchStarted
      )
    },
    get partnerTiers() {
      return Array.from(self.providers.values())
        .filter(provider => provider.partnerTier)
        .map(provider => provider.partnerTier)
    },
    get showResults() {
      if (
        self.partnerId &&
        self.partner &&
        self.partner.findAProviderConfig.isShowProviderResultsEnabled
      ) {
        return 'providers'
      }
      return 'locations'
    },
    /**
     * @description Gets a location model from the list of loaded
     * locations by locationId. This only works when locations are
     * loaded ahead of time. This will not automatically load a
     * location by provided id.
     *
     * @returns {Location|null} LocationModel
     */
    get selectedLocation() {
      if (self.locationId) {
        return self.locations.get(self.locationId)
      }
      return null
    },
    get canShowResultsPanel() {
      return self.searchStore.searchStarted || self.locationStore.hasLocations
    },
  }))
  .actions(self => ({
    afterCreate() {
      const { partnerId, staticPage } = camelizeKeys(
        qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })
      )
      if (staticPage) {
        self.staticPage = Boolean(staticPage)
      }
      if (partnerId) {
        self.partnerId = partnerId
        self.fetchPartner()
      }

      self.fetchSpecialties()

      onPatch(self, patch => {
        switch (patch.path) {
          case '/locationId':
            if (patch.value !== '') {
              self.locationStore.maybeFetchLocation(patch.value)
            }
            break
          case '/practiceId':
            if (patch.value !== '') {
              self.practiceStore.maybeFetchPractice(patch.value)
            }
            break
          default:
        }
      })
    },
    fetchPartner() {
      if (!self.partnerId || self.staticPage) {
        return
      }
      const query = self.query(PARTNER, { id: self.partnerId })

      if (query.loading) {
        self.debug(`LOADING PARTNER CONFIG FOR ${self.partnerId}`)
      }

      query.then(
        () => {
          self.debug(`FINISHED LOADING PARTNER CONFIG FOR ${self.partnerId}`)
        },
        error => {
          getEnv(self).logger.error(error)
        }
      )
    },
    fetchSpecialties() {
      self.querySpecialties().then(
        response => {
          self.setSpecialties(response.specialties)
        },
        error => {
          getEnv(self).logger.error(error)
        }
      )
    },
    setMapCenter(
      bounds = {
        south: 25.82,
        west: -124.39,
        north: 49.38,
        east: -66.94,
      }
    ) {
      self.boundingBox = bounds
    },
    setSpecialties(specialties) {
      self.specialties = specialties
    },
    openRoot() {
      self.page = '/'
      self.practiceId = ''
      self.providerId = ''
      self.locationId = ''
    },
    openPractice(practiceId) {
      self.page = 'practice'
      self.practiceId = practiceId
      self.locationId = ''
      self.providerId = ''
    },
    openPracticeLocation(practiceId, locationId) {
      self.page = 'practiceLocation'
      self.practiceId = practiceId
      self.locationId = locationId
    },
    openProviderLocation(providerId, locationId) {
      self.page = 'providerLocation'
      self.providerId = providerId
      self.locationId = locationId
    },
    modalClose() {
      if (self.staticPage) {
        return self.openPractice(self.practiceId)
      }
      return self.openRoot()
    },
    clearLocations() {
      self.locations.clear()
    },
    clearProviders() {
      self.providers.clear()
    },
    setLoading(value) {
      self.loading = value
    },
    resetErrorMessage() {
      self.errorMessage = ''
    },
    setErrorMessage(message) {
      self.errorMessage = message
    },
    debug(debug) {
      getEnv(self).logger.debug(debug)
    },
    error(error) {
      getEnv(self).logger.error(error)
    },
    showAllLocations() {
      self.partner.maybeFetchAllLocations()
      self.setMapCenter()
    },
    setLocationId(locationId) {
      self.locationId = locationId
    },
  }))
