import { PracticeAppointmentAnalyticsModelBase } from "./PracticeAppointmentAnalyticsModel.base"


/* A graphql query fragment builders for PracticeAppointmentAnalyticsModel */
export { selectFromPracticeAppointmentAnalytics, practiceAppointmentAnalyticsModelPrimitives, PracticeAppointmentAnalyticsModelSelector } from "./PracticeAppointmentAnalyticsModel.base"

/**
 * PracticeAppointmentAnalyticsModel
 *
 * Appointment stats for a practice
 */
export const PracticeAppointmentAnalyticsModel = PracticeAppointmentAnalyticsModelBase
